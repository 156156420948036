import { Component, OnInit, Input, OnChanges } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';
import { fuseAnimations } from '@fuse/animations';
import { Router } from '@angular/router';
import { HelpersService } from 'app/main/content/services/helpers/helpers.service';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';
import { MatDialog } from '@angular/material';
import { FileRetryViewComponent } from '../file-upload/file-retry-view/file-retry-view.component';

@Component({
  selector: 'app-file-preview',
  templateUrl: './file-preview.component.html',
  styleUrls: ['./file-preview.component.scss'],
  animations   : fuseAnimations
})
export class FilePreviewComponent implements OnInit, OnChanges {
  @Input() fileId;
  @Input() field;

  @Input() isScanque;
  @Input() translateData: any;
  @Input() scanqueEditUrl;
  pdfURL: any;
  retry: any = 1;
  getError: string;
  getLoading: boolean;
  finalError: boolean;
  fileType: any;

  constructor(
    private http: HttpClient,
    private sanitizer: DomSanitizer,
    private router: Router,
    private helperService: HelpersService,
    private translationLoader: FuseTranslationLoaderService,
    public dialog: MatDialog,
  ) { }
  
  
  ngOnChanges(changes: any) {

    if (changes && changes.translateData && changes.translateData.currentValue) {
      this.translateData = changes.translateData.currentValue;
      this.translationLoader.loadTranslationsV2(this.translateData);    
    }
  }

    ngOnInit() {
      console.log("filefile", this.field);
      this.translationLoader.loadTranslationsV2(this.translateData);

      this.pdfURL = null;
      if (this.fileId && typeof(this.fileId) === 'object') {
        this.fileId = this.fileId['id'];
      }
      if (this.field.requestType === "url") {
        this.getDocumentByUrl(this.fileId);
      } else {
        this.getDocument(this.fileId);
      }
    }

    moveTo(scanqueEditUrl) {
      this.router.navigate([scanqueEditUrl]);
    }

    getDocument(fileId: string) {
      if (fileId) {
        this.getLoading = true;
        this.helperService.getDocument(fileId)
          .then((res) => {
            console.log('getLoading', this.getLoading);
            this.getLoading = false;
            const blob = this.helperService.b64toBlob(res['base64'], 'application/pdf', null);
            const blobUrl = URL.createObjectURL(blob);
            this.pdfURL = this.sanitizer.bypassSecurityTrustResourceUrl(blobUrl);
            console.log("this.pdfURL", this.pdfURL);
          }).catch(err => {
            console.log('getLoading', this.getLoading);
            this.getError = 'retry';
            this.openRetryView(fileId);
            console.info(err);
          });
      }
    }

    getDocumentByUrl(fileId: string) {
      if (fileId) {
        this.getLoading = true;
        this.helperService.getDocumentUrl(fileId)
          .then((res) => {
            console.log('getLoading', this.getLoading);
            this.getLoading = false;
            this.pdfURL = this.sanitizer.bypassSecurityTrustResourceUrl(res.url);
            // this.mimeType = res.
            this.fileType = this.helperService.getFileTypeByMime(res.ContentType)
          }).catch(err => {
            console.log('getLoading', this.getLoading);
            this.getError = 'retry';
            this.openRetryView(fileId);
            console.info(err);
          });
      }
    }

  fileFound(eve: any) {
    console.log(eve);
  }

  
  openRetryView(id: string) {
    const temp = this.dialog.open(FileRetryViewComponent, {
      disableClose: false,
      width: '40%',
      height: '400px',
      restoreFocus: false,
      panelClass: 'happ-form-builder-dialog',
      data: {
        url: `api/offices/files/${id}/getBase64File`,
        id
      }
    });

    temp.afterClosed().subscribe((data) => {
      if (data) {
        this.getError = '';
        this.getLoading = false;
        const blob = this.helperService.b64toBlob(data['base64'], 'application/pdf', null);
        const blobUrl = URL.createObjectURL(blob);
        this.pdfURL = this.sanitizer.bypassSecurityTrustResourceUrl(blobUrl);
      }
    });
  }
}
