import { INg5courseSetting } from 'app/main/content/interfaces/register.interface';
import { SelectIconComponent } from '../../workspace-management/select-icon/select-icon.component';
import { DescriptionEditComponent } from 'app/main/content/components/description-edit/description-edit.component';
import { RegisterSelectionViewComponent } from 'app/main/content/components/register-selection-view/register-selection-view.component';
import * as objectPath from 'object-path';
import { HardFilterSetupComponent } from 'app/main/content/components/hard-filter-setup/hard-filter-setup.component';
import { SelectFormPopupComponent } from 'app/main/content/components/select-form-popup/select-form-popup.component';
import { WsTemplateSelectionComponent } from 'app/main/content/components/ws-template-selection/ws-template-selection.component';

export class GeneralSettings {
    general = [
        {
          'type': 'field',
          'settings':  {
            updateApi: '',
            fields: [
              {
                fieldType: 'select',
                title: 'workspace.datatable_view_type',
                description: 'workspace.datatable_view_type_desc',
                initValue: '',
                defaultValue: 'datatableViewType',
                keyName: 'datatableViewType',
                accessLevel: 'user',
                options: [
                    {
                        title: 'workspace.dattable',
                        value: 'datatableViewType'
                    },
                    {
                        title: 'workspace.card',
                        value: 'card'
                    }
                ]
              },
              {
                fieldType: 'select',
                title: 'workspace.datatable_data_type',
                description: 'workspace.datatable_data_type_desc',
                initValue: '',
                defaultValue: 'singleRegister',
                keyName: 'dataTypeReg',
                accessLevel: 'user',
                options: [
                    {
                        title: 'workspace.single_register',
                        value: 'singleRegister'
                    },
                    {
                        title: 'workspace.multi_register',
                        value: 'multiRegister'
                    }
                ]
              },
              {
                fieldType: 'popup',
                title: 'workspace.select_register',
                description: 'workspace.select_register_desc',
                initValue: '',
                defaultValue: [],
                keyName: 'selectedRegValues',
                accessLevel: 'user',
                component: RegisterSelectionViewComponent,
                height: '80%',
                width: '80%',
                buttonTitle: 'workspace.select_register',
                extraData: {},
                filterString: 'dataTypeReg = "multiRegister"'
              },
              {
                fieldType: 'input',
                title: 'workspace.app_name',
                description: 'workspace.app_name_desc',
                initValue: '',
                defaultValue: '',
                keyName: 'stepLabel',
                accessLevel: 'user'
              },
              {
                fieldType: 'input',
                title: 'workspace.item_name',
                description: 'workspace.item_name_desc',
                initValue: '',
                defaultValue: '',
                keyName: 'itemName',
                accessLevel: 'user'
              },
              {
                fieldType: 'input',
                type: 'number',
                title: 'workspace.sort',
                description: 'workspace.sort_desc',
                initValue: '',
                defaultValue: 50,
                keyName: 'sort',
                accessLevel: 'user'
              },
              {
                fieldType: 'input',
                type: 'text',
                title: 'workspace.left_min_width',
                description: 'workspace.left_min_width_desc',
                initValue: '',
                defaultValue: '200px',
                keyName: 'leftMinWidth',
                accessLevel: 'user'
              },
              {
                fieldType: 'popup',
                title: 'workspace.icon',
                description: 'workspace.icon_desc',
                initValue: '',
                defaultValue: 'apps',
                keyName: 'icon',
                accessLevel: 'user',
                component: SelectIconComponent,
                height: '',
                width: '',
                buttonTitle: 'workspace.icon',
                extraData: {}
              },
              {
                fieldType: 'checkbox',
                title: 'workspace.show_in_drop_down',
                description: 'workspace.show_in_drop_down_desc',
                initValue: '',
                defaultValue: false,
                keyName: 'showInDropDown',
                accessLevel: 'user'
              },
              {
                fieldType: 'checkbox',
                title: 'workspace.show_in_mobile',
                description: 'workspace.show_in_mobile_desc',
                initValue: '',
                defaultValue: false,
                keyName: 'showInMobile',
                accessLevel: 'user'
              },
              {
                fieldType: 'popup',
                title: 'workspace.description',
                description: 'workspace.description_desc',
                initValue: '',
                defaultValue: '',
                keyName: 'description',
                accessLevel: 'user',
                extraData: { fieldType: 'editor' },
                component: DescriptionEditComponent,
                buttonTitle: 'workspace.description',
                height: '420px',
                width: '60%'
              },
              {
                fieldType: 'route',
                title: 'workspace.move_to_process',
                description: 'workspace.move_to_process_desc',
                initValue: '',
                defaultValue: '',
                keyName: 'integratedProcessId',
                accessLevel: 'user',
                showValue: true,
                routeType: 'process',
                buttonTitle: 'workspace.move_to_process',
                filterString: 'integratedProcessId != ""'
              },
              {
                fieldType: 'route',
                title: 'workspace.move_to_register',
                description: 'workspace.move_to_register_desc',
                initValue: '',
                defaultValue: '',
                keyName: 'regId',
                accessLevel: 'user',
                showValue: true,
                routeType: 'register',
                buttonTitle: 'workspace.move_to_register',
                filterString: 'regId != ""'
              },
            ]
        }
      }
    ];

    advance: any = [
      {
        'type': 'field',
        'settings':  {
          updateApi: '',
          fields: [
            {
              fieldType: 'route',
              title: 'workspace.move_to_process',
              description: 'workspace.move_to_process_desc',
              initValue: '',
              defaultValue: '',
              keyName: 'integratedProcessId',
              accessLevel: 'user',
              showValue: true,
              routeType: 'process',
              buttonTitle: 'workspace.move_to_process',
              filterString: 'integratedProcessId != ""'
            },
            {
              fieldType: 'route',
              title: 'workspace.move_to_register',
              description: 'workspace.move_to_register_desc',
              initValue: '',
              defaultValue: '',
              keyName: 'regId',
              accessLevel: 'user',
              showValue: true,
              routeType: 'register',
              buttonTitle: 'workspace.move_to_register',
              filterString: 'regId != ""'
            },
            {
              fieldType: 'popup',
              title: 'workspace.hard_filter',
              description: 'workspace.hard_filter_desc',
              initValue: '',
              defaultValue: '',
              keyName: 'hardFilter',
              accessLevel: 'user',
              component: HardFilterSetupComponent,
              height: '350px',
              width: '600px',
              buttonTitle: 'workspace.hard_filter',
              extraData: {}
            },
            {
              fieldType: 'popup',
              title: 'workspace.dt_table_form',
              description: 'workspace.dt_table_form_desc',
              initValue: '',
              defaultValue: '',
              keyName: 'formId',
              accessLevel: 'user',
              extraData: { allowSelection: true },
              component: SelectFormPopupComponent,
              buttonTitle: 'workspace.select_form',
              height: '100%',
              width: '100%'
            },
            {
              fieldType: 'popup',
              title: 'workspace.select_template',
              description: 'workspace.select_template_desc',
              initValue: [],
              defaultValue: [],
              keyName: 'templateList',
              accessLevel: 'user',
              component: WsTemplateSelectionComponent,
              buttonTitle: 'workspace.select_template',
              height: '100%',
              width: '100%'
            },
          ]
      }
    }
  ];
    
    settingJson = [
      {
        'title': 'workspace.stared_actions',
        'type': 'section',
        'settings': [
            {
              'title': 'workspace.stared_actions',
              'children': [ ...this.advance ]
            }
        ]
      },
        {
            'title': 'workspace.general',
            'type': 'section',
            'settings': [
                {
                  'title': 'workspace.general',
                  'children': [ ...this.general ]
                }
            ]
        }
    ];
    constructor(url: string, appData: INg5courseSetting = {} as any, moreInfo: any = {}) {
        this.settingJson.forEach((setting) => {
            setting.settings.forEach((sectionSetting) => {
                sectionSetting.children.forEach((fieldSetting: any) => {
                    fieldSetting.settings = fieldSetting.settings || {};
                    fieldSetting.settings.updateApi = url;
                    fieldSetting.settings['triggerSub'] = moreInfo.triggerSub;
                    fieldSetting.settings.fields = fieldSetting.settings.fields || [];
                    fieldSetting.settings.fields.forEach((field: any) => {
                        field['initValue'] = (objectPath.get(appData, field.keyName) || objectPath.get(appData, field.keyName) === false) ? 
                        objectPath.get(appData, field.keyName) : field['defaultValue'];
                        field['dialogRef'] = moreInfo.dialogRef;
                        if (field.fieldType === 'select') {
                          field.options = field.options || [];
                          field.options = field.options.concat(moreInfo[field.keyName] || []);
                        }
                        if (field.fieldType === 'popup') {
                          moreInfo[field.keyName] = moreInfo[field.keyName] || {};
                          field.extraData = field.extraData || {};
                          field.extraData = Object.assign(moreInfo[field.keyName], field.extraData);
                        }
                    });
                });
            });
        });
    }

    get settingsModel () {
        return this.settingJson;
    }
}
