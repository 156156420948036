<div class="pb-12" *ngIf="!field.hide || builder">
    <div class="happ-list-field" [class]="field.class"
        [fxLayout]="((field.label_alignment === 'left') ? 'row' : 'column')+' wrap'">
        <div *ngIf="field.label_alignment !== 'hide'" class="happ-default-title happ-title"
            [ngStyle]="{width: (formData && formData.leftLabelWidth) || '100%'}">
            <p *ngIf="!hideTitle" class="happ-field-title mr-12">{{ field.title | translate }} : </p>
        </div>
        <div class="happ-list happ-content" *ngIf="!field.multiple && !loading" fxLayout="{{field.flex}} wrap">
            <button class="mt-4 mr-4 form-setting-icon-button" [ngClass]="{'sml-icn-btn': field.smallButton}"
                *ngIf="field.showEdit && !field.userRegister && field.show_edit_button_align !== 'last'"
                (click)="editOptions()" [matTooltip]="'ct.edit_options' | translate" mat-raised-button>
                <mat-icon [ngClass]="{'sml-icon': field.smallButton}">settings</mat-icon>
            </button>
            <button [disabled]="field.showdisablecontroltype || field.disable" class="happ-button-list happ-normal-btn"
                [ngClass]="{'happ-small-button': field.smallButton, 'happ-selected-button': ((option.value && model == option.value) || (option.title && model == option.title))}" class="my-4"
                [style.text-align]="(field.align)? field.align : 'center'" mat-raised-button
                (click)="simpleButton(option)" *ngFor="let option of field.options"
                [color]="((option.value && model == option.value) || (option.title && model == option.title))? 'primary' : ''">
                {{ option.title || option.value }} </button>
            <button class="mt-4 ml-4 form-setting-icon-button" [ngClass]="{'sml-icn-btn': field.smallButton}"
                *ngIf="field.showEdit && !field.userRegister && field.show_edit_button_align === 'last'"
                (click)="editOptions()" [matTooltip]="'ct.edit_options' | translate" mat-raised-button>
                <mat-icon [ngClass]="{'sml-icon': field.smallButton}">settings</mat-icon>
            </button>
        </div>
        <div class="happ-list happ-content" *ngIf="field.multiple && !loading" fxLayout="{{field.flex}} wrap">
            <button class="mt-4  form-setting-icon-button" [ngClass]="{'sml-icn-btn': field.smallButton}"
                *ngIf="field.showEdit && !field.userRegister && field.show_edit_button_align !== 'last'"
                (click)="editOptions()" [matTooltip]="'ct.edit_options' | translate" mat-raised-button>
                <mat-icon [ngClass]="{'sml-icon': field.smallButton}">settings</mat-icon>
            </button>
            <button [disabled]="field.showdisablecontroltype || field.disable" class="happ-button-list happ-normal-btn"
                [ngClass]="{'happ-small-button': field.smallButton, 'happ-selected-button': (getLength && getLength.includes(option.value || option.title))}" class="my-4" mat-raised-button
                [style.text-align]="(field.align)? field.align : 'center'"
                (click)="onChange(option.value || option.title)" role="listitem" *ngFor="let option of field.options"
                [color]="(getLength && getLength.includes(option.value || option.title))? 'primary' : ''">
                {{ option.title || option.value }} </button>
            <button class="mt-4 form-setting-icon-button" [ngClass]="{'sml-icn-btn': field.smallButton}" [ngClass]="{'happ-small-button': field.smallButton}"
                *ngIf="field.showEdit && !field.userRegister && field.show_edit_button_align === 'last'"
                (click)="editOptions()" [matTooltip]="'ct.edit_options' | translate" mat-raised-button>
                <mat-icon [ngClass]="{'sml-icon': field.smallButton}">settings</mat-icon>
            </button>
        </div>
        <div fxLayoutAlign="center center" *ngIf="loading">
            <mat-spinner></mat-spinner>
        </div>
        <div style="background: red;">
            <p *ngIf="errorMessage" class="m-0 ml-8" style="color: white;"> {{ errorMessage | translate  }} </p>
        </div>
        <hr *ngIf="errorMessage" >
    </div>
    <div class="happ-desc" *ngIf="field.allow_description && field.description"
        [innerHTML]="field.description | htmlWithStyle">
    </div>
</div>