import * as tslib_1 from "tslib";
import { OnInit, EventEmitter, ChangeDetectorRef, OnChanges } from '@angular/core';
import { MatDialog, MatSnackBar } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { HelpersService } from 'app/main/content/services/helpers/helpers.service';
import { ProcessButtonCommentPopupComponent } from '../../process-button-comment-popup/process-button-comment-popup.component';
var ProcessStatusComponent = /** @class */ (function () {
    function ProcessStatusComponent(snackBar, helperService, dialog, _changeDetectorRef, translateService) {
        this.snackBar = snackBar;
        this.helperService = helperService;
        this.dialog = dialog;
        this._changeDetectorRef = _changeDetectorRef;
        this.translateService = translateService;
        this.onFieldValueUpdated = new EventEmitter();
        this.buttonChange = new EventEmitter();
        this.updateRecord = new EventEmitter();
        this.isErrorStatus = false;
        this.errorInfo = {};
        this.buttons = [];
    }
    ProcessStatusComponent.prototype.ngOnChanges = function (changes) {
        if (changes && changes.buttonApiData && !changes.buttonApiData.firstChange) {
            console.log("Changes data ", changes);
            this.buttonApiData = changes.buttonApiData.currentValue;
            this.getButtons();
        }
    };
    ProcessStatusComponent.prototype.ngOnInit = function () {
        var _this = this;
        this.getButtons();
        this.currentLang = this.translateService.getDefaultLang();
        this.translateService.onLangChange.subscribe(function (data2) {
            _this.currentLang = data2;
        });
    };
    ProcessStatusComponent.prototype.getButtons = function () {
        var data = this.buttonApiData;
        this.buttons = JSON.parse(JSON.stringify(data.buttons)) || [];
        if (this.field.processButtonFilter) {
            this.field.filterStatuses = this.field.filterStatuses || '';
            var tempIds_1 = this.field.filterStatuses.split(",").map(function (ele) { return (ele.trim()); });
            if (this.field.processButtonFilter === 'show') {
                this.buttons = this.buttons.filter(function (ele) { return (ele.dest && tempIds_1.includes(ele.dest.id + "")); });
            }
            else if (this.field.processButtonFilter === 'hide') {
                this.buttons = this.buttons.filter(function (ele) { return (ele.dest && !tempIds_1.includes(ele.dest.id + "")); });
            }
        }
        this.isErrorStatus = data.errorStatus;
        this.errorInfo = data.errorInfo || {};
        this.buttons = this.transformSort(this.buttons);
        this._changeDetectorRef.detectChanges();
        // const selectedOffice = JSON.parse(localStorage.getItem('ngStorage-selectedOffice'));
        // if (this.model && this.model['_id'] && this.model['regId']) {
        //   this.officeId = (selectedOffice && selectedOffice['_id']);
        //   this.helperService.getRequest(`api/reg-process/${this.model['instanceId']}/getButtons`)
        //     .then((data) => {
        //     }).catch(err => {
        //       console.info(err);
        //     });
        // }
    };
    ProcessStatusComponent.prototype.onChange = function (button) {
        var _this = this;
        console.log("buttons", button);
        if (button.enableComment) {
            var temp = this.dialog.open(ProcessButtonCommentPopupComponent, {
                disableClose: false,
                width: '40%',
                maxWidth: '80%',
                height: 'max-content',
                restoreFocus: false,
                panelClass: 'happ-form-builder-dialog',
                data: {
                    commentText: button.commentText
                }
            });
            temp.afterClosed()
                .subscribe(function (data) {
                if (data) {
                    _this.processButton(button, data);
                }
            });
        }
        else {
            this.processButton(button, null);
        }
    };
    ProcessStatusComponent.prototype.processButton = function (button, comment) {
        if (this.model['processId'] && this.model['instanceId'] && button && button.id) {
            console.log(button.loadingText);
            var loading = (button && button.loadingText && (button.loadingText[this.currentLang] || button.loadingText['en'])) || '';
            this.updateRecord.emit({
                isUpdateRecord: !button.dont_update_on_transition,
                updatedData: this.model,
                regId: this.regId,
                initBehaviour: '',
                loadingText: loading || button.loadingText,
                recId: this.recId,
                buttonId: button.id,
                type: 'actButton',
                comment: comment,
                navigateTo: "oneRecordView/process/" + this.model['processId'] + "/instances/" + this.model['instanceId'] + "/processing/" + button.id + "/" + decodeURI(button.label.replace(/\s+/g, '-')) + "?loading=" + loading
            });
            if (this.field.haveRules) {
                this.onFieldValueUpdated.emit({
                    label: this.field.label,
                    value: this.model,
                    ruleIndex: this.field.ruleIndex,
                    field: this.field
                });
            }
        }
    };
    ProcessStatusComponent.prototype.transformSort = function (array) {
        if (!array || array && array.length === 0) {
            return [];
        }
        array.sort(function (a, b) {
            if (a && b && a && b && a['sort'] < b['sort']) {
                return -1;
            }
            else if (a && b && a && b && a['sort'] > b['sort']) {
                return 1;
            }
            else {
                return 0;
            }
        });
        return array;
    };
    ProcessStatusComponent.prototype.retry = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            return tslib_1.__generator(this, function (_a) {
                try {
                    this.updateRecord.emit({
                        isUpdateRecord: true,
                        updatedData: this.model,
                        regId: this.regId,
                        initBehaviour: '',
                        recId: this.recId,
                        buttonId: this.errorInfo.id,
                        type: 'actButton',
                        navigateTo: "oneRecordView/process/" + this.model['processId'] + "/instances/" + this.model['instanceId'] + "/processing/" + this.errorInfo.id + "/" + decodeURI(this.errorInfo.label.replace(/\s+/g, '-'))
                    });
                }
                catch (err) {
                    this.helperService.openSnackBar(err);
                }
                return [2 /*return*/];
            });
        });
    };
    ProcessStatusComponent.prototype.moveBack = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var err_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.helperService.getRequest("api/reg-process/" + this.model['instanceId'] + "/moveBack")];
                    case 1:
                        _a.sent();
                        this.updateRecord.emit({
                            goBack: true
                        });
                        return [3 /*break*/, 3];
                    case 2:
                        err_1 = _a.sent();
                        this.helperService.openSnackBar(err_1);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    ProcessStatusComponent.prototype.informAdmin = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var err_2;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 2, , 3]);
                        return [4 /*yield*/, this.helperService.getRequest("api/reg-process/" + this.model['instanceId'] + "/informAdmin")];
                    case 1:
                        _a.sent();
                        this.updateRecord.emit({
                            goBack: true
                        });
                        return [3 /*break*/, 3];
                    case 2:
                        err_2 = _a.sent();
                        this.helperService.openSnackBar(err_2);
                        return [3 /*break*/, 3];
                    case 3: return [2 /*return*/];
                }
            });
        });
    };
    return ProcessStatusComponent;
}());
export { ProcessStatusComponent };
