import * as tslib_1 from "tslib";
import { HttpClient } from '@angular/common/http';
import { MatSnackBar, MatDialog } from '@angular/material';
import { BehaviorSubject } from 'rxjs/BehaviorSubject';
import { ConfirmChangesComponent } from '../confirm-changes/confirm-changes.component';
import { HelpersService } from '../../services/helpers/helpers.service';
import { FormRulesService } from '../../services/rules/form-rules.service';
import * as objectPath from 'object-path';
import { Subject } from 'rxjs';
import * as _ from 'lodash';
var FormComponentService = /** @class */ (function () {
    function FormComponentService(http, snackBar, dialog, helperService, formRuleService) {
        var _this = this;
        this.http = http;
        this.snackBar = snackBar;
        this.dialog = dialog;
        this.helperService = helperService;
        this.formRuleService = formRuleService;
        this.onFormDataChanged = new BehaviorSubject(null);
        this.onRecIdChanged = new BehaviorSubject(null);
        this.onRecordRefresh = new Subject();
        this.isStepper = false;
        this.formComponentDefaultValue = {};
        this.loading = false;
        this.formData = {};
        this.formName = '';
        this.operation = 'new';
        this.tabId = 1;
        this.error = [];
        this.createNewRec = false;
        this.oldRecordData = {};
        this.NOT_UPDATING_KEY_NAMES = [
            'recCheckList',
            'recComments',
            'recComment',
            'office_json',
            'logged_user',
        ];
        this.changedFormData = {};
        this.saveButton = false;
        this.buttonApiData = {};
        this.traverse = function (o, fn) {
            if (!this.formData) {
                this.formData = {};
            }
            // tslint:disable-next-line:forin
            for (var i in o) {
                if (i === 'data') {
                    if (this.obj['multiple']) {
                        // this.formData[this.obj['label']] = this.formData[this.obj['label']] || this.obj['data'] || {};
                    }
                    else {
                        if (this.obj['control_type'] === 'process_status') {
                            this.processStatusField = this.obj;
                        }
                        // this.formData[this.obj['label']] = this.formData[this.obj['label']] || this.obj['data'] || '';
                    }
                }
                fn.apply(this, [i, o[i]]);
                if (o[i] !== null && typeof (o[i]) === 'object') {
                    this.obj = o[i];
                    this.traverse(o[i], fn);
                }
            }
        };
        this.onFormDataChanged
            .subscribe(function (formData) { return tslib_1.__awaiter(_this, void 0, void 0, function () {
            var _this = this;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!(formData && !formData['formData'])) return [3 /*break*/, 3];
                        this.regId = formData['regId'];
                        this.operation = formData['operation'] || 'new';
                        this.formData = {};
                        this.oldRecordData = {};
                        // default value passed when required.
                        this.formComponentDefaultValue = formData['defaultValue'] || {};
                        this.changedFormData = formData;
                        this.saveButton = formData['saveButton'];
                        this.auditInfo = formData['auditInfo'];
                        if (!formData['recId']) return [3 /*break*/, 2];
                        this.recId = formData['recId'];
                        return [4 /*yield*/, this.getRecordData()];
                    case 1:
                        _a.sent();
                        _a.label = 2;
                    case 2:
                        console.log("111", this.formId, this.regId);
                        this.formId = formData['formId'] || this.formId;
                        if (this.auditInfo) {
                            this.isStepper = true;
                        }
                        this.loading = true;
                        this.makeAllFormSettings().then(function () {
                            _this.setLoadingFalse();
                        }).catch(function (err) {
                            console.error(err);
                            _this.setLoadingFalse();
                        });
                        return [3 /*break*/, 4];
                    case 3:
                        if (formData && formData['formData']) {
                            this.applicationType = 'externalWidget';
                            this.externalRecId = formData['formData']['externalRecId'];
                            this.redirectURL = formData['formData']['redirectURL'];
                            this.form = formData['formData']['ng5Form'];
                            this.formId = formData['formData']['_id'];
                        }
                        _a.label = 4;
                    case 4: return [2 /*return*/];
                }
            });
        }); }, function (err) {
            console.info(err);
        });
        this.onRecIdChanged
            .subscribe(function (recId) {
            if (recId) {
                console.log("----- temp data");
                _this.operation = 'edit';
                _this.recId = recId;
                _this.getRecordData()
                    .then(function (data) {
                    if (!_this.formData) {
                        _this.formData = {};
                    }
                    _this.setLoadingFalse();
                    _this.oldRecordData = JSON.parse(JSON.stringify(_this.formData));
                })
                    .catch(function (err) {
                    console.error(err);
                    _this.setLoadingFalse();
                });
            }
        }, function (err) {
            console.info(err);
        });
    }
    FormComponentService.prototype.makeAllFormSettings = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var error_1;
            return tslib_1.__generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        _a.trys.push([0, 4, , 5]);
                        if (!this.regId) return [3 /*break*/, 3];
                        return [4 /*yield*/, this.getFormId()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, this.getForm()];
                    case 2:
                        _a.sent();
                        this.oldRecordData = JSON.parse(JSON.stringify(this.formData));
                        this.form = this.formRuleService.checkAllRules(this.ngForm.ng5Form, this.ngForm.rules, this.formData, true);
                        this.checkForTabConditions();
                        console.log("this.form", this.form);
                        _a.label = 3;
                    case 3: return [3 /*break*/, 5];
                    case 4:
                        error_1 = _a.sent();
                        throw error_1;
                    case 5: return [2 /*return*/];
                }
            });
        });
    };
    FormComponentService.prototype.setLoadingFalse = function () {
        var _this = this;
        setTimeout(function () {
            _this.loading = false;
        }, 600);
    };
    FormComponentService.prototype.makeInit = function () {
        this.formId = '';
        this.formName = '';
        this.loading = false;
        this.templates = [];
        this.error = [];
        this.form = null;
        this.formData = {};
        this.processStatusField = null;
        this.buttonApiData = {};
    };
    FormComponentService.prototype.getFormId = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            if (!_this.formId) {
                _this.helperService.getRegisterData(_this.regId)
                    .then(function (regData) {
                    if (regData) {
                        console.log("222", _this.formId);
                        _this.formId = regData['ng5FormId'];
                        resolve(_this.formId);
                    }
                    else {
                        resolve('');
                    }
                }).catch(function (err) {
                    console.info(err);
                    reject(err);
                });
            }
            else {
                resolve('');
            }
        });
    };
    /**
     * get the form
     */
    FormComponentService.prototype.getForm = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.helperService.getForm(_this.formId)
                .then(function (formData) {
                _this.afterGetFormOperation(formData)
                    .then(resolve).catch(reject);
            }).catch(reject);
        });
    };
    FormComponentService.prototype.recordCreated = function (data) {
        this.operation = 'update';
        this.formData = data;
        this.recId = data._id;
    };
    /**
   *
   * @param formData
   * operations to be done after get form.
   */
    FormComponentService.prototype.afterGetFormOperation = function (formData) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.ngForm = formData;
            _this.formName = formData['title'];
            _this.templates = formData['formTemplates'] || [];
            _this.form = formData['ng5Form'] || [];
            _this.traverse(_this.form, function (k, v) {
            });
            _this.getDefaultValue(_this.form || [], '')
                .then(function () {
                _this.checkForTabConditions();
                resolve(_this.form);
            })
                .catch(function (err) {
                reject(err);
            });
        });
    };
    FormComponentService.prototype.checkForTabConditions = function () {
        var _this = this;
        console.log("checkForTabConditions");
        this.form = this.form || [];
        this.ngForm['ng5Form'] = this.ngForm['ng5Form'] || [];
        this.ngForm['ng5Form'] = this.ngForm['ng5Form'].filter(function (ele) { return (!ele.showOnApp); });
        var tabName = window.localStorage.getItem(this.regId + "_name");
        this.form = this.ngForm['ng5Form'].filter(function (tab) {
            if (tab && !tab.useCondition) {
                return true;
            }
            else if (tab) {
                var retValue_1 = true;
                if (tab.condition && _this.formData) {
                    if (!Array.isArray(tab.condition)) {
                        tab.condition = [];
                    }
                    tab.condition.forEach(function (ele, i) {
                        switch (ele.condition) {
                            case 'isEqual':
                                if (tab.condition && tab.condition[i - 1] && tab.condition[i - 1].operator === "AND") {
                                    if (retValue_1) {
                                        retValue_1 = (_this.formData[ele.field] == ele.value);
                                    }
                                }
                                else if (tab.condition && tab.condition[i - 1] && tab.condition[i - 1].operator === "OR") {
                                    if (!retValue_1) {
                                        retValue_1 = (_this.formData[ele.field] == ele.value);
                                    }
                                }
                                else {
                                    retValue_1 = (_this.formData[ele.field] == ele.value);
                                }
                                // tslint:disable-next-line:triple-equals
                                // return (this.formData[ele.field] == ele.value);
                                break;
                            case 'isNotEqual':
                                // tslint:disable-next-line:triple-equals
                                if (tab.condition && tab.condition[i - 1] && tab.condition[i - 1].operator === "AND") {
                                    if (retValue_1) {
                                        retValue_1 = (_this.formData[ele.field] != ele.value);
                                    }
                                }
                                else if (tab.condition && tab.condition[i - 1] && tab.condition[i - 1].operator === "OR") {
                                    if (!retValue_1) {
                                        retValue_1 = (_this.formData[ele.field] != ele.value);
                                    }
                                }
                                else {
                                    retValue_1 = (_this.formData[ele.field] != ele.value);
                                }
                                break;
                        }
                    });
                }
                return retValue_1;
            }
            return true;
        });
        if (tabName) {
            var newIndex = _.findIndex(this.form, { tabTitle: tabName });
            if (newIndex >= 0) {
                this.selectedTab = newIndex;
                if (!this.selectedTab) {
                    this.selectedTab = 0;
                }
                localStorage.setItem(this.regId, newIndex.toString());
            }
        }
    };
    FormComponentService.prototype.getRecordData = function () {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var recData, _a, recData, _b, error_2;
            return tslib_1.__generator(this, function (_c) {
                switch (_c.label) {
                    case 0:
                        _c.trys.push([0, 13, , 14]);
                        if (!(this.operation === 'add' || this.recId === 'new' || !this.recId)) return [3 /*break*/, 1];
                        return [2 /*return*/, null];
                    case 1:
                        if (!this.regId) return [3 /*break*/, 7];
                        return [4 /*yield*/, this.helperService.getRegisterRecordData(this.regId, this.recId)];
                    case 2:
                        recData = _c.sent();
                        if (!recData) return [3 /*break*/, 6];
                        if (!(recData && recData.instanceId)) return [3 /*break*/, 4];
                        _a = this;
                        return [4 /*yield*/, this.helperService.getRequest("api/reg-process/" + recData.instanceId + "/getButtons")];
                    case 3:
                        _a.buttonApiData = _c.sent();
                        _c.label = 4;
                    case 4:
                        this.formData = recData || {};
                        this.oldRecordData = recData || {};
                        this.formatFormDataForDot();
                        // this.getDefaultValue(this.form || [], '');
                        this.formId = (recData && recData.formId);
                        console.log("this.formId", this.formId);
                        return [4 /*yield*/, this.makeAllFormSettings()];
                    case 5:
                        _c.sent();
                        this.checkForTabConditions();
                        return [2 /*return*/, (recData || {})];
                    case 6: return [3 /*break*/, 12];
                    case 7: return [4 /*yield*/, this.helperService.getRegisterRecordDataV2(this.recId)];
                    case 8:
                        recData = _c.sent();
                        if (!recData) return [3 /*break*/, 12];
                        if (!(recData && recData.instanceId)) return [3 /*break*/, 10];
                        _b = this;
                        return [4 /*yield*/, this.helperService.getRequest("api/reg-process/" + recData.instanceId + "/getButtons")];
                    case 9:
                        _b.buttonApiData = _c.sent();
                        _c.label = 10;
                    case 10:
                        this.regId = recData.regId;
                        this.formData = recData || {};
                        this.oldRecordData = recData || {};
                        this.formatFormDataForDot();
                        this.formId = (recData && recData.formId);
                        return [4 /*yield*/, this.makeAllFormSettings()];
                    case 11:
                        _c.sent();
                        this.checkForTabConditions();
                        // this.getDefaultValue(this.form || [], '');
                        return [2 /*return*/, (recData || {})];
                    case 12: return [3 /*break*/, 14];
                    case 13:
                        error_2 = _c.sent();
                        throw error_2;
                    case 14: return [2 /*return*/];
                }
            });
        });
    };
    FormComponentService.prototype.preventUpdateKeys = function (data) {
        var flatFields = this.helperService.getXTypeVariables(this.form, '', true) || [];
        console.log("Values = ", flatFields, data);
        flatFields.forEach(function (ele) {
            if (ele.preventUpdate) {
                delete data[ele.label];
            }
        });
        return data;
    };
    FormComponentService.prototype.formatFormDataForDot = function () {
        // tslint:disable-next-line:forin
        for (var i in this.formData) {
            if (i.includes('.')) {
                this.formData[i] = this.getUsingString(this.formData, i);
                console.log("assiging the data- ", i, this.formData[i]);
            }
        }
    };
    FormComponentService.prototype.saveFormData = function (notClose) {
        var _this = this;
        if (notClose === void 0) { notClose = false; }
        return new Promise(function (resolve, reject) {
            _this.loading = true;
            if (_this.operation === 'new') {
                _this.saveData(_this.formData)
                    .then(function (data) {
                    // successe
                    // this.location.back();
                    if (!notClose) {
                        _this.closePopup(true);
                    }
                    _this.loading = false;
                    resolve(null);
                    _this.snackBar.open('Data Saved', 'Done', {
                        duration: 2000,
                    });
                })
                    .catch(function (err) {
                    _this.loading = false;
                    // error
                    if (!notClose) {
                        _this.closePopup(null);
                    }
                    reject('Error Occured While updating the form');
                    _this.snackBar.open(err, 'done', {
                        duration: 2000,
                    });
                });
            }
            else {
                if (_this.checkChanges(_this.oldRecordData, _this.formData)) {
                    if (_this.ngForm.changeConfirmPopup) {
                        _this.confirmDialogRef = _this.dialog.open(ConfirmChangesComponent, {
                            disableClose: false,
                            width: '40%',
                            data: { confirmText: (_this.ngForm && _this.ngForm.popupOptions && _this.ngForm.popupOptions.confirmText) || '',
                                resetText: (_this.ngForm && _this.ngForm.popupOptions && _this.ngForm.popupOptions.resetText) || 'Spowoduje to zresetowanie danych',
                                cancelText: (_this.ngForm && _this.ngForm.popupOptions && _this.ngForm.popupOptions.cancelText) || 'Aby kontynuowac edycje' },
                        });
                        _this.confirmDialogRef.afterClosed().subscribe(function (result) {
                            if (result === 'confirm') {
                                _this.updateData(_this.formData)
                                    .then(function (data) {
                                    // success
                                    // this.location.back();
                                    resolve(null);
                                    if (!notClose) {
                                        _this.closePopup(true);
                                    }
                                    _this.snackBar.open('updated', 'done', {
                                        duration: 2000,
                                    });
                                    _this.loading = false;
                                })
                                    .catch(function (err) {
                                    _this.loading = false;
                                    // error
                                    if (!notClose) {
                                        _this.closePopup(null);
                                    }
                                    reject('Error Occured While updating the form');
                                    _this.snackBar.open(err, 'done', {
                                        duration: 2000,
                                    });
                                });
                            }
                            else if (result === 'reset') {
                                resolve(null);
                                if (!notClose) {
                                    _this.closePopup(true);
                                }
                                _this.snackBar.open('no changes', 'done', {
                                    duration: 2000,
                                });
                                _this.loading = false;
                                console.log('no changes');
                            }
                            _this.confirmDialogRef = null;
                        }, function (err) {
                            console.info(err);
                        });
                    }
                    else {
                        _this.updateData(_this.formData)
                            .then(function (data) {
                            // success
                            // this.location.back();
                            resolve(null);
                            if (!notClose) {
                                _this.closePopup(true);
                            }
                            _this.snackBar.open('updated', 'done', {
                                duration: 2000,
                            });
                            _this.loading = false;
                        })
                            .catch(function (err) {
                            _this.loading = false;
                            // error
                            if (!notClose) {
                                _this.closePopup(null);
                            }
                            reject('Error Occured While updating the form');
                            _this.snackBar.open(err, 'done', {
                                duration: 2000,
                            });
                        });
                    }
                }
                else {
                    resolve(null);
                    _this.loading = false;
                    if (!notClose) {
                        _this.closePopup(true);
                    }
                    _this.snackBar.open('no changes', 'done', {
                        duration: 2000,
                    });
                    console.log('no changes');
                }
            }
        });
    };
    FormComponentService.prototype.closePopup = function (data) {
        if (this.changedFormData && this.changedFormData['popupOptions'] && this.changedFormData['popupOptions']['dialogRef']) {
            this.changedFormData['popupOptions']['dialogRef'].close(data);
        }
    };
    FormComponentService.prototype.saveData = function (data) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            if (_this.regId) {
                data = _this.calculationOnSave(data);
                data = _this.preventUpdateKeys(data);
                _this.helperService.createRegisterRecord(_this.regId, data)
                    .then(function (res) {
                    console.log("res data = ", res);
                    if (_this.isStepper) {
                        // now update the audit regsiter.
                        _this.auditInfo['courseSteps'][_this.auditInfo['stepNo']]['recId'] = data['result']['_id'];
                        _this.updateRegData(_this.auditInfo['regId'], { ng5courseSetting: _this.auditInfo['courseSteps'] })
                            .then(function () {
                            resolve('');
                        })
                            .catch(reject);
                    }
                    else {
                        resolve('');
                    }
                }).catch(reject);
            }
            else {
                reject('No register Id Found');
            }
        });
    };
    FormComponentService.prototype.checkChanges = function (oldData, newData) {
        for (var i in newData) {
            if (typeof newData[i] === 'object') {
                for (var j in newData[i]) {
                    if (newData[i][j] !== oldData[i][j]) {
                        return true;
                    }
                }
            }
            else if (newData[i] !== oldData[i]) {
                return true;
            }
        }
        return false;
    };
    FormComponentService.prototype.externalWidgetSaveData = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            if (_this.formData && _this.externalRecId) {
                _this.helperService.postRequest("api/external-widget/form/" + _this.externalRecId, _this.formData)
                    .then(function (res) {
                    // now update the audit regsiter.
                    _this.snackBar.open('Data Saved', 'done', {
                        duration: 2000,
                    });
                    if (_this.redirectURL) {
                        window.location.href = _this.redirectURL;
                    }
                }).catch(function (err) {
                    _this.snackBar.open(err || 'Error occurred', 'done', {
                        duration: 2000,
                    });
                    reject(err);
                });
            }
            else {
                reject('No register Id Found or form data found');
            }
        });
    };
    FormComponentService.prototype.updateData = function (data, regId, recId) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            if (_this.params && _this.params['parentId']) {
                data['parentId'] = _this.params['parentId'];
            }
            _this.formatObject();
            data = _this.calculationOnSave(data);
            data = _this.preventUpdateKeys(data);
            _this.buildInstanceReport(data)
                .then((function (newRec) {
                data = newRec;
                _this.regId = _this.regId || regId;
                _this.recId = _this.recId || recId;
                _this.NOT_UPDATING_KEY_NAMES.forEach(function (ele) { return (delete data[ele]); });
                _this.helperService.updateRegisterRecord(_this.regId, _this.recId, data)
                    .then(function (updatedDoc) {
                    resolve(updatedDoc[0]);
                }).catch(reject);
            })).catch(reject);
        });
    };
    FormComponentService.prototype.updateRegData = function (regId, regData) {
        var tempRegData = JSON.parse(JSON.stringify(regData));
        delete tempRegData['_id'];
        return this.helperService.updateRegisterData(regId, tempRegData);
    };
    FormComponentService.prototype.formatObject = function () {
        this.formData = this.formData || {};
        for (var i in this.formData) {
            if (i && i.includes('.')) {
                objectPath.set(this.formData, i, this.formData[i]);
                delete this.formData[i];
            }
        }
    };
    FormComponentService.prototype.getUsingString = function (recData, label) {
        return objectPath.get(recData, label);
    };
    FormComponentService.prototype.formatFormData = function () {
        var _this = this;
        return new Promise(function (resolve, reject) {
            if (_this.operation === 'new' || !_this.recId) {
                resolve(null);
            }
            var formVar = [];
            var tt = _this;
            _this.traverse(_this.form, function (k, v) {
                if (k === 'data') {
                    formVar.push(v);
                    // tt.formData[v] = tt.formData[v] || ""  ;
                }
            });
            resolve(formVar);
        });
    };
    FormComponentService.prototype.createNewRecFun = function (regId) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            _this.helperService.createRegisterRecord(regId, {})
                .then(function (data) {
                _this.recId = data['_id'];
            }).catch(reject);
        });
    };
    FormComponentService.prototype.calculationOnSave = function (data) {
        var _this = this;
        var tempFields = this.helperService.getXTypeVariables(this.form, 'calculation_on_save') || [];
        tempFields = this.transformSort(tempFields, 'calculationSort');
        tempFields.forEach(function (ele) {
            var sum = 0;
            if (ele['fields']) {
                sum += _this.helperService.rowCalculateOnSave(ele, data);
            }
            data[ele['label']] = sum;
            // this is to save title too..
            if (ele['saveRange']) {
                ele['range'] = ele['range'] || [];
                ele['range'].forEach(function (range) {
                    if (sum >= range.min && sum <= range.max) {
                        data[ele['rangeLabel']] = range.title || sum;
                    }
                });
            }
        });
        return data;
    };
    FormComponentService.prototype.transformSort = function (array, field) {
        if (!array || array && array.length === 0) {
            return [];
        }
        array.sort(function (a, b) {
            if (a && b && a[field] < b[field]) {
                return -1;
            }
            else if (a && b && a[field] > b[field]) {
                return 1;
            }
            else {
                return 0;
            }
        });
        return array;
    };
    FormComponentService.prototype.getDefaultValue = function (obj, control_type, all) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            var ng5AllFields = [];
            obj = obj || [];
            obj.forEach(function (tabValue) {
                if (tabValue.tabContent && typeof tabValue.tabContent === 'object') {
                    tabValue.tabContent.forEach(function (value) {
                        if (value.data !== null) {
                            if (value.label) {
                                _this.formData[value.label] = _this.formData[value.label] || value.data || _this.formComponentDefaultValue[value.label] || '';
                            }
                        }
                        if (value.children && typeof value.children === 'object') {
                            value.children.forEach(function (childValue) {
                                if (childValue.data !== null) {
                                    if (childValue.label) {
                                        _this.formData[childValue.label] = _this.formData[childValue.label] || childValue.data || _this.formComponentDefaultValue[childValue.label] || '';
                                    }
                                }
                                if (childValue && typeof childValue === 'object') {
                                    childValue.forEach(function (nestedChildValue) {
                                        // tslint:disable-next-line:max-line-length
                                        if (nestedChildValue.data !== null) {
                                            if (nestedChildValue.label) {
                                                // tslint:disable-next-line:max-line-length
                                                _this.formData[nestedChildValue.label] = _this.formData[nestedChildValue.label] || nestedChildValue.data || _this.formComponentDefaultValue[nestedChildValue.label] || '';
                                            }
                                        }
                                    });
                                }
                            });
                        }
                    });
                }
            });
            resolve(ng5AllFields);
        });
    };
    FormComponentService.prototype.buildInstanceReport = function (recData) {
        var _this = this;
        return new Promise(function (resolve, reject) {
            try {
                var reportFields_1 = [];
                var reportData_1 = {
                    reportTitle: (_this.ngForm && _this.ngForm['registerRecordReportTitle']) || '',
                    reportFields: []
                };
                _this.traverseField(_this.form, function (field) {
                    if (field.formPrint) {
                        reportFields_1.push(field);
                    }
                }).then(function (data) {
                    reportFields_1 = reportFields_1 || [];
                    if (reportFields_1.length) {
                        reportFields_1.forEach(function (field) {
                            reportData_1.reportFields.push({
                                key: field.title || field.label,
                                value: _this.formatFieldData(field, recData)
                            });
                        });
                        recData['instanceReportData'] = recData['instanceReportData'] || {};
                        if (reportData_1 && reportData_1.reportFields && reportData_1.reportFields.length) {
                            recData['instanceReportData']['recordReportSection'] = recData['instanceReportData']['recordReportSection'] || [];
                            recData['instanceReportData']['recordReportSection'].push(reportData_1);
                        }
                        resolve(recData);
                    }
                    else {
                        resolve(recData);
                    }
                }).catch(function (err) {
                    console.info(err);
                    resolve(recData);
                });
            }
            catch (err) {
                reject(err);
            }
        });
    };
    FormComponentService.prototype.formatFieldData = function (field, value) {
        try {
            if (field['control_type'] === 'reg_value_selection') {
                if (value[field.label] && value[field.label]['value']) {
                    // tslint:disable-next-line:forin
                    for (var reg_val in value[field.label]) {
                        return value[field.label][reg_val];
                    }
                }
            }
            else if (typeof value[field.label] === 'object') {
                var tempVal = '';
                for (var k in value[field.label]) {
                    if (value[field.label][k]) {
                        tempVal += k + ", ";
                    }
                }
                return tempVal.substr(0, tempVal.length - 1);
            }
            else {
                return value[field.label] || '';
            }
        }
        catch (err) {
            console.info(err);
        }
    };
    FormComponentService.prototype.traverseField = function (obj, condFunc) {
        return new Promise(function (resolve, reject) {
            var ng5AllFields = [];
            if (obj) {
                obj.forEach(function (tabValue) {
                    if (tabValue.tabContent && typeof tabValue.tabContent === 'object') {
                        tabValue.tabContent.forEach(function (value) {
                            condFunc(value);
                            if (value.children && typeof value.children === 'object') {
                                value.children.forEach(function (childValue) {
                                    condFunc(childValue);
                                    if (childValue && typeof childValue === 'object') {
                                        childValue.forEach(function (nestedChildValue) {
                                            condFunc(nestedChildValue);
                                        });
                                    }
                                });
                            }
                        });
                    }
                });
                resolve(ng5AllFields);
            }
            else {
                reject('No Form Data - please check that form is correct');
            }
        });
    };
    FormComponentService.prototype.loadDataFromRecData = function (recData) {
        return tslib_1.__awaiter(this, void 0, void 0, function () {
            var tt;
            return tslib_1.__generator(this, function (_a) {
                try {
                    tt = JSON.parse(localStorage.getItem('wsAppData')) || {};
                    recData = recData || {};
                    if (recData) {
                        if (!this.regId) {
                            this.regId = (recData && recData['regId']);
                        }
                        this.formData = recData || {};
                        this.instanceId = recData['instanceId'];
                        tt['recName'] = recData['signatura'] || '';
                        tt['statusId2'] = recData['processStatusId'] || '';
                        tt['instanceId2'] = recData['instanceId'] || '';
                        localStorage.setItem('wsAppData', JSON.stringify(tt));
                        if (recData && recData['formId']) {
                            this.processFormId = recData['formId'];
                            if (!this.isStepper) {
                                this.formId = recData['formId'];
                            }
                        }
                        else {
                            console.log('No form id in the record');
                        }
                        return [2 /*return*/, recData];
                    }
                    else {
                        return [2 /*return*/, null];
                    }
                }
                catch (error) {
                    throw error;
                }
                return [2 /*return*/];
            });
        });
    };
    return FormComponentService;
}());
export { FormComponentService };
