<ng-container *ngIf="formComponentService.formData && formComponentService.formData.harcodeViewTypes; else formTmpl">
    <div class="content">
        <ng-container *ngIf="!formComponentService.loading">

            <ng-container [ngSwitch]="formComponentService.formData.harcodeViewTypes">
                <ng-container *ngSwitchCase="'providerServiceSelection'">
                    <div style="width: 100%;">
                        <app-provider-service-selection (processUpdateRecord)="processUpdateRecord($event)"
                            (updateDataFromCt)="updateDataFromCt($event)" [translateData]="translateData"
                            [staticCommentField]="staticProcessStatus" [editNg5Service]="formComponentService"
                            [staticProcessStatus]="staticProcessStatus"></app-provider-service-selection>
                    </div>
                </ng-container>
                <ng-container *ngSwitchCase="'clientServiceSelection'">
                    <div style="width: 100%;">
                        <app-client-service-selection (processUpdateRecord)="processUpdateRecord($event)"
                            (updateDataFromCt)="updateDataFromCt($event)" [translateData]="translateData"
                            [staticCommentField]="staticProcessStatus" [editNg5Service]="formComponentService"
                            [staticProcessStatus]="staticProcessStatus"></app-client-service-selection>
                    </div>
                </ng-container>
                <ng-container *ngSwitchCase="'agentTimeProposalBuilder'">
                    <div style="width: 100%;">
                        <app-agent-proposal-builder (processUpdateRecord)="processUpdateRecord($event)"
                            (updateDataFromCt)="updateDataFromCt($event)" [translateData]="translateData"
                            [staticCommentField]="staticProcessStatus" [editNg5Service]="formComponentService"
                            [staticProcessStatus]="staticProcessStatus"></app-agent-proposal-builder>
                    </div>
                </ng-container>
                <ng-container *ngSwitchCase="'clientTimeProposalBuilder'">
                    <div style="width: 100%;">
                        <app-client-proposal-selection (processUpdateRecord)="processUpdateRecord($event)"
                            (updateDataFromCt)="updateDataFromCt($event)" [translateData]="translateData"
                            [staticCommentField]="staticProcessStatus" [editNg5Service]="formComponentService"
                            [staticProcessStatus]="staticProcessStatus"></app-client-proposal-selection>
                    </div>
                </ng-container>
                <ng-container *ngSwitchCase="'paymentView'">
                    <div style="width: 100%;">
                        <app-client-order-payment (processUpdateRecord)="processUpdateRecord($event)"
                            (updateDataFromCt)="updateDataFromCt($event)" [translateData]="translateData"
                            [staticCommentField]="staticProcessStatus" [editNg5Service]="formComponentService"
                            [staticProcessStatus]="staticProcessStatus"></app-client-order-payment>
                    </div>
                </ng-container>
                <ng-container *ngSwitchDefault>
                    <h2>Nothing</h2>
                </ng-container>
            </ng-container>
        </ng-container>

        <div fxLayoutAlign="center center" *ngIf="formComponentService.loading" style="width: 100%;">
            <!-- <mat-spinner></mat-spinner> -->
            <div fxLayout="column" style="justify-content: center; align-items: center;">
                <h2 style="font-weight: 600; text-align: center;">
                    <span *ngIf="loadingText">
                        {{ loadingText | textTranslate:currentLang }}
                    </span>
                    <span *ngIf="!loadingText">
                        {{ 'ONE_REC.WAIT_PLEASE_PROCESSING' | translate }}
                    </span>
                    <!-- WAIT_PLEASE_PROCESSING -->
                </h2>
                <img style="width: 100px; margin-top: 24px; margin-left: 10px;" src="/assets/loading/loading_dot.gif"
                    alt="smile">
            </div>
        </div>
    </div>
</ng-container>
<ng-template #formTmpl>
    <div class="page-layout simple fullwidth mt-12" style="height: calc(100vh - 90px);" fusePerfectScrollbar>
        <div class="top-bg accent"></div>
        <div class="center" style="height: -webkit-fill-available; padding: 12px;">
            <div style="height: calc(100vh - 90px)" *ngIf="formComponentService.loading" fxLayoutAlign="center center">
                <mat-spinner></mat-spinner>
            </div>
            <div fxLayout="row" *ngIf="!formComponentService.loading && formComponentService.form"
                style="margin: auto; height: -webkit-fill-available;"
                [style.width]="(formComponentService.ngForm && formComponentService.ngForm.formWidth)? (formComponentService.ngForm.formWidth + '%') : '100%' "
                class="content-card mat-elevation-z4 happ-form-container-div">

                <div *ngIf="!formComponentService.loading" class="w-100-p">
                    <div class="happ-action-header">
                        <div *ngIf="formComponentService.processStatusField && formComponentService.processStatusField.showOnHeader"
                            class="mb-24">
                            <div class="my-8"
                                *ngIf="!formComponentService.processStatusField.hide && formComponentService.error && formComponentService.error.length === 0">
                                <app-process-status [(model)]="formComponentService.formData"
                                    [buttonApiData]="formComponentService.buttonApiData"
                                    (updateRecord)="processUpdateRecord($event)"
                                    [field]="formComponentService.processStatusField"></app-process-status>
                            </div>
                            <div fxLayoutAlign="center center"
                                *ngIf="!formComponentService.processStatusField.hide && formComponentService.error && formComponentService.error.length > 0">
                                <h3>
                                    Please Remove all the Errors to have button
                                </h3>
                            </div>
                        </div>
                    </div>

                    <div class="happ-tab-view fuse-white-bg" fxLayout="column">

                        <div *ngIf="!formComponentService.loading && (!formComponentService.ngForm.formConfig || (formComponentService.ngForm.formConfig && !formComponentService.ngForm.formConfig.hideFormHeader))"
                            class="toolbar px-24 py-8 pb-0 mb-8 pb-4 mt-12">
                            <ng-container *ngIf="formComponentService.form && formComponentService.form.length > 1">
                                <ng-container *ngIf="!isSmallScreen">
                                    <div *ngFor="let tab of formComponentService.form; let i=index" class="nav-item"
                                        aria-label="inbox">
                                        <button mat-raised-button [color]="(currentView == i)? 'primary': ''"
                                            *fuseIfOnDom [@animate]="{value:'*',params:{delay:'100ms',x:'-25px'}}"
                                            (click)="changeTab(i)">
                                            {{tab.tabTitle | translate}}
                                        </button>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="isSmallScreen">
                                    <mat-form-field class="w-100-p happ-small-form-field-outline" floatLabel="always"
                                        appearance="outline">
                                        <mat-select [(ngModel)]="currentView"
                                            (selectionChange)="changeSelection($event)">
                                            <mat-option *ngFor="let tab of formComponentService.form; let i=index"
                                                [value]="i">
                                                {{tab.tabTitle | translate}}
                                            </mat-option>
                                        </mat-select>
                                    </mat-form-field>
                                </ng-container>
                            </ng-container>
                            <span fxFlex></span>
                            <!-- <button class="mx-8" aria-label="back_button" style="color: black;" mat-raised-button
                            (click)="goBack()">
                            {{ 'ONE_REC.BACK' | translate }}
                        </button> -->
                            <div
                                *ngIf="formComponentService && formComponentService.ngForm && formComponentService.ngForm.showDeleteButton">
                                <button aria-label="delete_record" mat-raised-button class="mr-8" *fuseIfOnDom
                                    [@animate]="{value:'*',params:{delay:'100ms',y:'-25px'}}"
                                    (click)="deleteRecordConfirm()">
                                    {{ 'ONE_REC.DELETE' | translate }}
                                </button>
                            </div>
                            <button aria-label="open_address_book"
                                *ngIf="formComponentService.ngForm && formComponentService.ngForm.showAddressBookPopup"
                                mat-raised-button (click)="openAddressBook()">
                                {{ 'ONE_REC.OPEN_ADDRESS_BOOK' | translate }}
                            </button>
                            <!-- <button aria-label="save_and_new" *ngIf="formComponentService.ngForm && formComponentService.ngForm.saveAndNew"
                            mat-raised-button (click)="saveAndNew()">
                            {{ 'ONE_REC.SAVE_AND_NEW' | translate }}
                        </button> -->
                            <button aria-label="update_form"
                                [disabled]="formComponentService.error && formComponentService.error.length > 0"
                                class="mx-4"
                                *ngIf="formComponentService.ngForm && this.ngForm && this.ngForm.saveWithoutMove"
                                mat-raised-button color="warn" (click)="formComponentService.saveFormData(false)">
                                {{((operation == 'new')? 'ONE_REC.SAVE':'ONE_REC.UPDATE') | translate }}
                            </button>


                            <button aria-label="update_form"
                                [disabled]="formComponentService.error && formComponentService.error.length > 0"
                                class="mx-4"
                                *ngIf="(formComponentService.ngForm && !formComponentService.ngForm['hideUpdateAndSave']) || (formComponentService.formData && !formComponentService.formData._id)"
                                mat-raised-button color="warn" (click)="formComponentService.saveFormData()">
                                {{((operation == 'new')? 'ONE_REC.SAVE_AND_BACK':'ONE_REC.UPDATE_AND_BACK') | translate }}
                                <!-- {{ 'ONE_REC.UPDATE_AND_BACK' | translate  }} -->
                            </button>
                        </div>
                        <div *ngIf="preview" class="p-12 m-16 mat-elevation-z1 fuse-white-bg"
                            [innerHTML]="preview | htmlWithStyle">
                        </div>
                        <!-- {{ formComponentService.formData | json }} -->
                        <div [ngClass]="{'happ-all-small-form-field-outline': (formComponentService && formComponentService.ngForm && formComponentService.ngForm.formConfig && formComponentService.ngForm.formConfig.smallFields)}"
                            class="content p-24 pt-4">
                            <div *ngFor="let tab of formComponentService.form; let i=index" style="width: 100%;"
                                [style.display]="(currentView == i)?'block':'none'">
                                <div *ngIf="currentView == i" [style.display]="(currentView == i)?'block':'none'"
                                    class="py-4">
                                    <div *ngFor="let row of tab.tabContent" fxLayout="row" fxFlex>

                                        <div [ngClass]="{'happ-error-border': (isArrayFields && isArrayFields[row.label])}"
                                            *fuseIfOnDom [@animate]="{value:'*', duration: '1000ms', params:{y:'100%'}}"
                                            [style.display]="(row.hide)?'none': ''" fxFlex>

                                            <app-all-fields-view (onFieldValueUpdated)="onFieldValueUpdate($event)"
                                                [translateData]="translateData" (getRecord)="getRecord($event)"
                                                (processUpdateRecord)="processUpdateRecord($event)"
                                                (updateAddress)="updateAddress($event)"
                                                (updateFormDataObj)="updateFormDataObj($event)"
                                                (updateParentOnSave)="updateParentOnSave($event)"
                                                (updateRecord)="updateRecord($event)"
                                                *ngIf="row.control_type !== 'section'"
                                                [tempService]="formComponentService" (onError)="checkError($event)"
                                                [field]="row"></app-all-fields-view>

                                            <!-- <<<<<<<<<<<<<<<  SECTION   >>>>>>>>>>>>>>>>> -->

                                            <div *ngIf="row.control_type === 'section'">
                                                <ng-container *ngIf="row.expandPanel">
                                                    <mat-accordion>
                                                        <mat-expansion-panel [expanded]="row.expanded">
                                                            <mat-expansion-panel-header>
                                                                <mat-panel-title>
                                                                    {{ row.title }}
                                                                </mat-panel-title>
                                                                <mat-panel-description>
                                                                    <!-- This is a summary of the content -->
                                                                </mat-panel-description>
                                                            </mat-expansion-panel-header>
                                                            <div style="padding: 0px 12px;" fxLayout="row wrap"
                                                                fxLayoutAlign="space-between top"
                                                                class=" fuse-white-bg happ-left-border"
                                                                *ngIf="!row.hide">
                                                                <div *ngFor="let col of row.children; let po=index"
                                                                    [fxFlex.gt-xs]="((row && row['width' + po]) ? (row['width' + po]) : (100/row.cols)) - 1"
                                                                    fxFlex.lt-md="100">

                                                                    <div *ngFor="let field of col">
                                                                        <app-all-fields-view
                                                                            (onFieldValueUpdated)="onFieldValueUpdate($event)"
                                                                            [translateData]="translateData"
                                                                            *ngIf="field.control_type !== 'section'"
                                                                            (getRecord)="getRecord($event)"
                                                                            [tempService]="formComponentService"
                                                                            (processUpdateRecord)="processUpdateRecord($event)"
                                                                            (updateAddress)="updateAddress($event)"
                                                                            (updateFormDataObj)="updateFormDataObj($event)"
                                                                            (updateParentOnSave)="updateParentOnSave($event)"
                                                                            (updateRecord)="updateRecord($event)"
                                                                            (onError)="checkError($event)"
                                                                            [field]="field"></app-all-fields-view>
                                                                    </div>

                                                                </div>

                                                            </div>
                                                        </mat-expansion-panel>
                                                    </mat-accordion>
                                                </ng-container>
                                                <ng-container *ngIf="!row.expandPanel">
                                                    <div fxLayout="row wrap" fxLayoutAlign="space-between top"
                                                        class=" fuse-white-bg happ-left-border" *ngIf="!row.hide">
                                                        <div *ngFor="let col of row.children; let po=index"
                                                            [fxFlex.gt-xs]="((row && row['width' + po]) ? (row['width' + po]) : (100/row.cols)) - 1"
                                                            fxFlex.lt-md="100">

                                                            <div *ngFor="let field of col">
                                                                <app-all-fields-view
                                                                    (onFieldValueUpdated)="onFieldValueUpdate($event)"
                                                                    [translateData]="translateData"
                                                                    *ngIf="field.control_type !== 'section'"
                                                                    (getRecord)="getRecord($event)"
                                                                    [tempService]="formComponentService"
                                                                    (processUpdateRecord)="processUpdateRecord($event)"
                                                                    (updateAddress)="updateAddress($event)"
                                                                    (updateFormDataObj)="updateFormDataObj($event)"
                                                                    (updateParentOnSave)="updateParentOnSave($event)"
                                                                    (updateRecord)="updateRecord($event)"
                                                                    (onError)="checkError($event)" [field]="field">
                                                                </app-all-fields-view>
                                                            </div>

                                                        </div>

                                                    </div>
                                                </ng-container>

                                            </div>
                                            <!-- <div *ngIf="row.control_type === 'section'">

                                            <div class=" fuse-white-bg  happ-left-border" *ngIf="!row.hide">
                                                <div *ngFor="let col of row.children" class="mr-16"
                                                    fxFlex="{{ 100/row.cols }}" fxLayout.lt-md="column wrap"
                                                    fxLayout="column wrap">

                                                    <div *ngFor="let field of col">
                                                        <div
                                                            [ngClass]="{'happ-error-border': (isArrayFields && isArrayFields[field.label])}">
                                                            <app-all-fields-view
                                                                (onFieldValueUpdated)="onFieldValueUpdate($event)"
                                                                [translateData]="translateData"
                                                                (getRecord)="getRecord($event)"
                                                                (processUpdateRecord)="processUpdateRecord($event)"
                                                                (updateAddress)="updateAddress($event)"
                                                                (updateParentOnSave)="updateParentOnSave($event)"
                                                                (updateRecord)="updateRecord($event)"
                                                                *ngIf="field.control_type !== 'section'"
                                                                [tempService]="formComponentService"
                                                                (onError)="checkError($event)" [field]="field">
                                                            </app-all-fields-view>
                                                        </div>
                                                    </div>

                                                </div>

                                            </div>
                                        </div> -->
                                            <!-- <<<<<<<<<<<<<<<  SECTION ENDS   >>>>>>>>>>>>>>>>> -->

                                        </div>
                                        <!-- <mat-divider></mat-divider></mat-divider> -->
                                        <!-- </mat-card> -->
                                    </div>
                                </div>

                            </div>
                        </div>

                    </div>

                    <div class="happ-action-header">
                        <div
                            *ngIf="formComponentService.processStatusField && formComponentService.processStatusField.showOnFooter">
                            <div class="my-8"
                                *ngIf="!formComponentService.processStatusField.hide && formComponentService.error && formComponentService.error.length === 0">
                                <app-process-status [(model)]="formComponentService.formData"
                                    [buttonApiData]="formComponentService.buttonApiData"
                                    (updateRecord)="processUpdateRecord($event)"
                                    [field]="formComponentService.processStatusField"></app-process-status>
                            </div>
                            <div fxLayoutAlign="center center"
                                *ngIf="formComponentService.error && formComponentService.error.length > 0">
                                <h3>
                                    Please Remove all the Errors to have button
                                </h3>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>

    </div>
</ng-template>