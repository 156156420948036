import { Component, OnInit, Input, Output, EventEmitter, ChangeDetectorRef, OnChanges, SimpleChanges } from '@angular/core';
import { MatDialog, MatSnackBar } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { HelpersService } from 'app/main/content/services/helpers/helpers.service';
import { ProcessButtonCommentPopupComponent } from '../../process-button-comment-popup/process-button-comment-popup.component';

@Component({
  selector: 'app-process-status',
  templateUrl: './process-status.component.html',
  styleUrls: ['./process-status.component.scss']
})
export class ProcessStatusComponent implements OnInit, OnChanges {
  @Input() field;
  @Output() onFieldValueUpdated: EventEmitter<Object> = new EventEmitter();
  @Input() builder;
  @Input() model;
  @Input() regId;
  @Input() recId;
  @Input() translateData: any;
  @Input() buttonApiData: any;
  @Output() buttonChange: EventEmitter<any> = new EventEmitter();
  @Output() updateRecord: EventEmitter<any> = new EventEmitter();
  public officeId: string;
  public isErrorStatus = false;
  public errorInfo: any = {};
  public buttons = [];
  currentLang: string;

  constructor(
    public snackBar: MatSnackBar,
    private helperService: HelpersService,
    public dialog: MatDialog,
    private _changeDetectorRef: ChangeDetectorRef,
    private translateService: TranslateService,

  ) { }
  ngOnChanges(changes: any): void {
    if (changes && changes.buttonApiData && !changes.buttonApiData.firstChange) {
      console.log("Changes data ", changes);
      this.buttonApiData = changes.buttonApiData.currentValue;
      this.getButtons();
    }
  }

  ngOnInit() {
    this.getButtons();
    this.currentLang = this.translateService.getDefaultLang();
    this.translateService.onLangChange.subscribe((data2: any) => {
      this.currentLang = data2;
    });
  }

  getButtons() {
    const data = this.buttonApiData;
    this.buttons = JSON.parse(JSON.stringify(data.buttons)) || [];

    if (this.field.processButtonFilter) {
      this.field.filterStatuses = this.field.filterStatuses || '';
      const tempIds = this.field.filterStatuses.split(",").map((ele: any) => (ele.trim()));
      if (this.field.processButtonFilter === 'show') {
        this.buttons = this.buttons.filter((ele: any) => (ele.dest && tempIds.includes(ele.dest.id+"")));
      } else if (this.field.processButtonFilter === 'hide') {
        this.buttons = this.buttons.filter((ele: any) => (ele.dest && !tempIds.includes(ele.dest.id+"")));
      }

    }
    this.isErrorStatus = data.errorStatus;
    this.errorInfo = data.errorInfo || {};
    this.buttons = this.transformSort(this.buttons);
    this._changeDetectorRef.detectChanges();
    // const selectedOffice = JSON.parse(localStorage.getItem('ngStorage-selectedOffice'));
    // if (this.model && this.model['_id'] && this.model['regId']) {
    //   this.officeId = (selectedOffice && selectedOffice['_id']);
    //   this.helperService.getRequest(`api/reg-process/${this.model['instanceId']}/getButtons`)
    //     .then((data) => {
          
    //     }).catch(err => {
    //       console.info(err);
    //     });
    // }
  }

  onChange(button) {

    console.log("buttons", button);
    if (button.enableComment) {
      const temp = this.dialog.open(ProcessButtonCommentPopupComponent, {
        disableClose: false,
        width: '40%',
        maxWidth: '80%',
        height: 'max-content',
        restoreFocus: false,
        panelClass: 'happ-form-builder-dialog',
        data: {
          commentText: button.commentText
        }
      });

      temp.afterClosed()
        .subscribe((data) => {
          if (data) {
            this.processButton(button, data);
          }
        });
    } else {
      this.processButton(button, null);
    }
  }

  processButton(button, comment) {
    if (this.model['processId'] && this.model['instanceId'] && button && button.id) {
      console.log(button.loadingText);
      const loading = (button && button.loadingText && (button.loadingText[this.currentLang] || button.loadingText['en'])) || '';
      this.updateRecord.emit({
        isUpdateRecord: !button.dont_update_on_transition,
        updatedData: this.model,
        regId: this.regId,
        initBehaviour: '',
        loadingText: loading || button.loadingText,
        recId: this.recId,
        buttonId: button.id,
        type: 'actButton',
        comment,
        navigateTo: `oneRecordView/process/${this.model['processId']}/instances/${this.model['instanceId']}/processing/${button.id}/${decodeURI(button.label.replace(/\s+/g, '-'))}?loading=${loading}`
      });
      if (this.field.haveRules) {
        this.onFieldValueUpdated.emit({
          label: this.field.label,
          value: this.model,
          ruleIndex: this.field.ruleIndex,
          field: this.field
        });
      }
    }
  }

  transformSort(array: any[]): any[] {
    if (!array || array && array.length === 0) {
      return [];
    }
    array.sort((a: any, b: any) => {
      if (a && b && a && b && a['sort'] < b['sort']) {
        return -1;
      } else if (a && b && a && b && a['sort'] > b['sort']) {
        return 1;
      } else {
        return 0;
      }
    });
    return array;
  }

  async retry() {
    try {
      this.updateRecord.emit({
        isUpdateRecord: true,
        updatedData: this.model,
        regId: this.regId,
        initBehaviour: '',
        recId: this.recId,
        buttonId: this.errorInfo.id,
        type: 'actButton',
        navigateTo: `oneRecordView/process/${this.model['processId']}/instances/${this.model['instanceId']}/processing/${this.errorInfo.id}/${decodeURI(this.errorInfo.label.replace(/\s+/g, '-'))}`
      });
    } catch (err) {
      this.helperService.openSnackBar(err);
    }
  }

  async moveBack() {
    try {
      await this.helperService.getRequest(`api/reg-process/${this.model['instanceId']}/moveBack`);
      this.updateRecord.emit({
        goBack: true
      });
    } catch (err) {
      this.helperService.openSnackBar(err);
    }
  }

  async informAdmin() {
    try {
      await this.helperService.getRequest(`api/reg-process/${this.model['instanceId']}/informAdmin`);
      this.updateRecord.emit({
        goBack: true
      });
    } catch (err) {
      this.helperService.openSnackBar(err);
    }
  }
}
