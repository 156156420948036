<!-- <button #openButton mat-icon-button class="open-button mat-primary-bg  (click)="openBar()">
    <mat-icon>settings</mat-icon>
</button> -->

<div class="theme-options-panel-overlay" #overlay [fxHide]="barClosed" [@fadeInOut]="!barClosed"></div>

<div #panel class="theme-options-panel fuse-white-bg mat-elevation-z8">

    <button mat-raised-button color="warn" [disabled]="(error.length)? true: false" *ngIf="data.recData" (click)="saveChanges()">Save</button>

    <button mat-icon-button class="close-button" (click)="closeBar()">
        <mat-icon>close</mat-icon>
    </button>

    <div class="theme-options-panel-inner mt-20" *ngIf="data && data.formFields && data.recData" fxLayout="column" fxLayoutAlign="start start">
        <div *ngFor="let row of data.formFields" class="w-100-p" >

          <div fxFlex style="width: 100%;" *ngIf="!row.hide && row.control_type" [ngSwitch]="row.control_type.control_type">
              <!-- <<<<<<<<<<<<<<<  INPUT TEXT   >>>>>>>>>>>>>>>>> -->
              <div *ngSwitchCase="'text'">
                  <div class="p-4 fuse-white-bg pl-12 happ-left-border" *ngIf="!row.hide">
                      <app-input-text (onError)="checkError($event)" [field]="row" [(model)]="data.recData[row.prop]"></app-input-text>
                  </div>
              </div>
              <div *ngSwitchCase="'Input Text'">
                  <div class="p-4 fuse-white-bg pl-12 happ-left-border" *ngIf="!row.hide">
                      <app-input-text (onError)="checkError($event)" [field]="row" [(model)]="data.recData[row.prop]"></app-input-text>
                  </div>
              </div>
              <div *ngSwitchCase="'email'">
                  <div class="p-4 fuse-white-bg pl-12 happ-left-border" *ngIf="!row.hide">
                      <app-email [field]="row" (onError)="checkError($event)" [(model)]="data.recData[row.prop]"></app-email>
                  </div>
              </div>
              <div *ngSwitchCase="'textarea'">
                  <div class="p-4 fuse-white-bg pl-12 happ-left-border" *ngIf="!row.hide">
                      <div *ngIf="row.allow_description && row.description" [innerHTML]="row.description | htmlWithStyle">

                      </div>
                      <mat-form-field style="width: 100%">
                          <textarea fxFlex style="resize: none;" rows="{{ row.no_of_rows}}" matInput placeholder="{{row.title}}" [disabled]="row.showdisablecontroltype=='true' || row.disable" [hidden]="row.hidden" [(ngModel)]="data.recData[row.prop]"></textarea>
                      </mat-form-field>
                  </div>
              </div>
              <div *ngSwitchCase="'text_description'">
                  <div class="p-4 fuse-white-bg pl-12 happ-left-border" *ngIf="!row.hide">
                      <p [style.color]="row.styles.color" [style.font-size]="row.styles.font_size+'px'" style="margin: 0px;" [style.background]="row.styles.background" [style.text-align]="row.styles.align" [style.font-weight]="(row.styles.bold)? 'bold': ''">
                          {{row.data}}
                      </p>
                  </div>
              </div>
              <!-- <<<<<<<<<<<<<<<  INPUT TEXT ENDS  >>>>>>>>>>>>>>>>> -->

              <!-- <<<<<<<<<<<<<<<  NUMBER STARTS   >>>>>>>>>>>>>>>>> -->
              <div *ngSwitchCase="'number'">
                  <div class="p-4 fuse-white-bg pl-12 happ-left-border" *ngIf="!row.hide">
                      <app-number [field]="row" [(model)]="data.recData[row.prop]"></app-number>
                  </div>
              </div>
              <div *ngSwitchCase="'currency'">
                  <div class="p-4 fuse-white-bg pl-12 happ-left-border" *ngIf="!row.hide">
                      <app-currency [field]="row" [(model)]="data.recData[row.prop]"></app-currency>
                  </div>
              </div>
              <div *ngSwitchCase="'phone'">
                  <div class="p-4 fuse-white-bg pl-12 happ-left-border" *ngIf="!row.hide">
                      <app-phone [field]="row" [(model)]="data.recData[row.prop]"></app-phone>
                  </div>
              </div>

              <div *ngSwitchCase="'date'">
                  <div class="p-4 fuse-white-bg pl-12 happ-left-border" *ngIf="!row.hide">
                      <app-date [field]="row" (onError)="checkError($event)" [(model)]="data.recData[row.prop]"></app-date>
                  </div>
              </div>

              <div *ngSwitchCase="'date-time'">
                  <div class="p-4 fuse-white-bg pl-12 happ-left-border" *ngIf="!row.hide">
                      <app-date-time (onError)="checkError($event)" [field]="row" [(model)]="data.recData[row.prop]"></app-date-time>
                  </div>
              </div>

              <!-- <div *ngSwitchCase="'date-range'">
                  <div class="p-4 fuse-white-bg pl-12 happ-left-border" *ngIf="!row.hide">
                      <app-date-range [field]="row" (onError)="checkError($event)" [(model)]="data.recData[row.prop]"></app-date-range>
                  </div>
              </div> -->

              <div *ngSwitchCase="'time'">
                  <div class="p-4 fuse-white-bg pl-12 happ-left-border" *ngIf="!row.hide">
                      <app-time [field]="row" (onError)="checkError($event)" [(model)]="data.recData[row.prop]"></app-time>
                  </div>
              </div>

              <!-- <<<<<<<<<<<<<<<  NUMBER ENDS   >>>>>>>>>>>>>>>>> -->

              <!-- <<<<<<<<<<<<<<<  FILE STARTS   >>>>>>>>>>>>>>>>> -->
              <div *ngSwitchCase="'file'">
                  <div class="p-4 fuse-white-bg pl-12" *ngIf="!row.hide">
                      <app-file-upload [recId]="editNg5Service.recId" [regId]="editNg5Service.regId" [field]="row" [(model)]="data.recData[row.prop]" [(recordMainDocument)]="data.recData['recordMainDocument']"></app-file-upload>
                  </div>
              </div>

              <div *ngSwitchCase="'file_preview'">
                  <app-file-preview [field]="row" [fileId]="data.recData[row.fileKey ||'recordMainDocument']"></app-file-preview>
              </div>
              <!-- <<<<<<<<<<<<<<<  FILE ENDS   >>>>>>>>>>>>>>>>> -->

              <!-- <<<<<<<<<<<<<<<  OTHER STARTS   >>>>>>>>>>>>>>>>> -->
              <div *ngSwitchCase="'slider'">
                  <div class="p-4 fuse-white-bg pl-12 happ-left-border" *ngIf="!row.hide">
                      <app-slider [field]="row" [(model)]="data.recData[row.prop]"></app-slider>
                  </div>
              </div>

              <div *ngSwitchCase="'switch'">
                  <div class="p-4 fuse-white-bg pl-12 happ-left-border" *ngIf="!row.hide">
                      <div *ngIf="!row.hidden">
                          <p> {{ row.title }} </p>
                          <mat-slide-toggle [disabled]="row.showdisablecontroltype=='true' || row.disable" #tog (change)="data.recData[row.prop] = (tog.checked)? row.trueVal: row.falseVal" [checked]="(data.recData[row.prop] == row.trueVal)? true: false">
                              {{data.recData[row.prop]}}
                          </mat-slide-toggle>
                      </div>
                  </div>
              </div>

              <!-- <<<<<<<<<<<<<<<  OTHER ENDS   >>>>>>>>>>>>>>>>> -->

              <!-- <<<<<<<<<<<<<<<  OPTION FIELDS STARTS   >>>>>>>>>>>>>>>>> -->
              <div *ngSwitchCase="'dropdown'">
                  <div class="p-4 fuse-white-bg pl-12 happ-left-border" *ngIf="!row.hide">
                      <mat-form-field fxFlex>
                          <mat-select fxFlex placeholder="{{row.title}}" [disabled]="row.showdisablecontroltype=='true' || row.disable" [hidden]="row.hidden" [(ngModel)]="data.recData[row.prop]">
                              <mat-option *ngFor="let option of row.options" [value]="option.value || option.title">
                                  {{ option.title || option.value }}
                              </mat-option>
                          </mat-select>
                      </mat-form-field>
                  </div>
              </div>

              <div *ngSwitchCase="'Radio Group'">
                  <div class="p-4 fuse-white-bg pl-12 happ-left-border" *ngIf="!row.hide">
                      <app-radio-group [field]="row" (onError)="checkError($event)" [(model)]="data.recData[row.prop]"></app-radio-group>
                  </div>
              </div>

              <div *ngSwitchCase="'List'">
                  <div class="p-4 fuse-white-bg pl-12 happ-left-border" *ngIf="!row.hide">
                      <app-list [field]="row" (onError)="checkError($event)" [(model)]="data.recData[row.prop]"></app-list>
                  </div>
              </div>

              <div *ngSwitchCase="'rwa-input'">
                  <div class="p-4 fuse-white-bg pl-12 happ-left-border" *ngIf="!row.hide">
                      <app-rwa-input [field]="row" (onError)="checkError($event)" [(rwa1)]="data.recData['rwa1']" [(rwa2)]="data.recData['rwa2']" [(rwa3)]="data.recData['rwa3']"
                      [(rwa4)]="data.recData['rwa4']" [(rwaTitle)]="data.recData['rwaTitle']" [(rwaDescription)]="data.recData['rwaDescription']" [(model)]="data.recData[row.prop]"></app-rwa-input>
                  </div>
              </div>

              <div *ngSwitchCase="'button-list'">
                  <div class="p-4 fuse-white-bg pl-12 happ-left-border" *ngIf="!row.hide">
                      <app-button-list [field]="row" (onError)="checkError($event)" [(model)]="data.recData[row.prop]"></app-button-list>
                  </div>
              </div>
              <!-- <<<<<<<<<<<<<<<  OPTION FIELDS ENDS   >>>>>>>>>>>>>>>>> -->

              <!-- <<<<<<<<<<<<<<<  MULTI SELECT FIELDS STARTS   >>>>>>>>>>>>>>>>> -->
              <div *ngSwitchCase="'checkbox'">
                  <div class="p-4 fuse-white-bg pl-12 happ-left-border" *ngIf="!row.hide">
                      <app-checkbox [field]="row" (onError)="checkError($event)" [(model)]="data.recData[row.prop]"></app-checkbox>
                  </div>
              </div>
              <!-- <<<<<<<<<<<<<<<  MULTI SELECT FIELDS ENDS   >>>>>>>>>>>>>>>>> -->
    </div>

</div>