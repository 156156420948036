import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormComponentService } from './form-component.service';
import { MatSnackBar, MatDialogRef, MatDialog } from '@angular/material';
import { Location } from '@angular/common';

import { fuseAnimations } from '@fuse/animations';
import { HelpersService } from '../../services/helpers/helpers.service';
import { ActivatedRoute, Router } from '@angular/router';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { FormRulesService } from '../../services/rules/form-rules.service';
import { STATIC_FIELDS } from '../../consts/staticFields.const';
import { FuseMatchMediaService } from '@fuse/services/match-media.service';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { MediaObserver } from '@angular/flex-layout';
import { FuseTranslationLoaderService } from '@fuse/services/translation-loader.service';

@Component({
  selector: 'app-form-component',
  templateUrl: './form-component.component.html',
  styleUrls: ['./form-component.component.scss'],
  animations : fuseAnimations
})
export class FormComponentComponent implements OnInit, OnDestroy {

  currentView: any;
  confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
  preview;
  isArrayFields = {};
  staticProcessStatus = STATIC_FIELDS.processStatus;

  translateData;
  isSmallScreen = false;
  loadingText: any;
  private _unsubscribeAll: Subject<any>;

  constructor(
    public formComponentService: FormComponentService,
    private location: Location,
    public snackBar: MatSnackBar,
    private router: Router,
    public dialog: MatDialog,
    private helperService: HelpersService,
    private translationLoader: FuseTranslationLoaderService,
    private _fuseMatchMediaService: FuseMatchMediaService,
    private _mediaObserver: MediaObserver,
    private formRuleService: FormRulesService,
    private route: ActivatedRoute

  ) {
    this._unsubscribeAll = new Subject();
   }

  ngOnDestroy(): void {
    console.log("form component in destroyed");
    this.formComponentService.makeInit();
    this._unsubscribeAll.next();
    this._unsubscribeAll.complete();
  }

  ngOnInit() {

    this._fuseMatchMediaService.onMediaChange
    .pipe(takeUntil(this._unsubscribeAll))
    .subscribe(() => {
        if ( this._mediaObserver.isActive('lt-md') )
        {
            this.isSmallScreen = true;
        } else {
          this.isSmallScreen = false;
        }
        console.log("isSmallScreen", this.isSmallScreen);
      });
    this.helperService.getTrans('control-template')
      .then((data2) => {
        this.translateData = data2;
      }).catch(err => {
        console.info(err);
      });
      this.helperService.getTrans('one-recordview')
      .then((data2) => {
        this.translationLoader.loadTranslationsV2(data2);
      }).catch(err => {
        console.info(err);
      });
    const tabId = localStorage.getItem(this.formComponentService.regId);
    if (tabId){
      this.currentView = tabId;
      localStorage.removeItem(this.formComponentService.regId);
    }
      else {
      this.currentView = 0;
        // localStorage.setItem("backPath", this.router.url);
        // this.changeTab(0);
      }
  }



  changeTab(i) {
    const prev = this.currentView;
    this.currentView = i;
    localStorage.setItem(this.formComponentService.regId, i);
  }

  goBack() {
      // this.location.back();
  }


  updateRecord(updated) {
    this.formComponentService.updateData(this.formComponentService.formData)
      .then(data => {
        // success
          this.snackBar.open('updated', 'done', {
            duration: 2000,
        });
        updated();
      })
      .catch(err => {
        // error
        this.snackBar.open(err, 'done', {
          duration: 2000,
      });
      });
  }

  checkError(e) {
    if (e['error'] && e['label']) {
      if ( this.formComponentService.error && !this.formComponentService.error.includes(e['label'])) {
        this.formComponentService.error.push(e['label']);
        this.isArrayFields[e['label']] = true;
      }
    } else {
      if (this.formComponentService.error && this.formComponentService.error.includes(e['label'])) {
        this.formComponentService.error.splice(this.formComponentService.error.indexOf(e['label']), 1);
        this.isArrayFields[e['label']] = false;
      }
    }
  }

  saveFormData() {
    console.log("closing the popup")
    this.formComponentService.saveFormData(true)
    .catch(err => {
      this.snackBar.open(err || 'Some error occured while updating', 'done', {
        duration: 2000,
      });
    });
  }


  processUpdateRecord(event) {
    console.log("button.loadingText 2", event);
    if (event.loadingText) {
      this.loadingText = event.loadingText;
    }
    if (event.goBack) {
      this.formComponentService.closePopup(null);
      this.goBack();
    } else if (event && event.isUpdateRecord) {
      this.formComponentService.updateData(this.formComponentService.formData, event.regId, event.recId)
      .then((updated) => {
        this.snackBar.open('Updated', 'Done', {
          duration: 2000,
        });
        // refresh the form and record
        this.refreshData(event.buttonId, event.type, event.comment);
      }).catch(err => {
        console.info(err);
      });
    } else {
      this.refreshData(event.buttonId, event.type, event.comment);
    }
  }
 
  async refreshData (buttonId: string, type: string, comment?: string) {
    try {
      // temp
      // this.formComponentService.closePopup(null);

      console.log(buttonId);
      this.formComponentService.loading = true;
      if (type === 'standalone') {
        const data = await this.helperService.getRequest(`api/reg-process/${this.formComponentService.formData.instanceId}/standalone/${buttonId}?comment=${comment}`);
        this.formComponentService.loading = false;
        
        this.formComponentService.makeInit();
        this.processNextInitBehaviour(data);
      } else if (type === 'toAnyStatus') {
        const data = await this.helperService.getRequest(`api/reg-process/${this.formComponentService.formData.instanceId}/any-status/${buttonId}?comment=${comment}`);
        this.formComponentService.loading = false;
        
        this.formComponentService.makeInit();
        this.processNextInitBehaviour(data);
      }  else {
        const activityData = await this.helperService.getProcessActivityDataV2(this.formComponentService.formData.instanceId, buttonId, comment);
        this.formComponentService.loading = false;

        this.formComponentService.makeInit();
        this.processNextInitBehaviour(activityData);
      }
    } catch (err) {
      this.errorOccured(err);
      console.info(err);
      this.formComponentService.loading = false;

    }
  }

  processNextInitBehaviour(activityData) {
    if (activityData && activityData.activity && activityData.activity.initStatusBehaviour === 'useSatusForm') {
      if (activityData.activity.formType === 'training') {
        this.formComponentService.loading = false;
        this.router.navigate([`/oneRecordView/training/${this.formComponentService.regId}/${this.formComponentService.recId}`]);
      } else {
        this.formComponentService.formId = activityData['ng5FormId'];
        this.formComponentService.afterGetFormOperation(activityData['ng5FormDoc'])
        .then(() => {
          this.formComponentService.loadDataFromRecData(activityData.recData)
          .catch(err => {
            console.info(err);
          });
          this.formComponentService.onRecordRefresh.next(true);
          this.formComponentService.formId = activityData['ng5FormId'];
          const tabId = localStorage.getItem(this.formComponentService.regId);
          // this.router.navigate(['./' + tabId], {relativeTo: this.route});
          this.formComponentService.loading = false;

        }).catch(err => {
          this.errorOccured(err);
          console.info(err);
        });
      }
    } else {
      this.formComponentService.closePopup(true);
      this.formComponentService.loading = false;
    }
  }

  errorOccured(err) {
    this.snackBar.open(err || 'Error Occured while processing.', 'Okay', {
      duration: 2000,
    });
    this.goBack();
    this.formComponentService.loading = false;
  }



  // processNextInitBehaviour(activityData) {
  //   if (activityData && activityData && activityData.initStatusBehaviour === 'useSatusForm') {
  //     if (activityData.formType === 'training') {
  //       this.formComponentService.loading = false;
  //       this.router.navigate([`/oneRecordView/training/${this.formComponentService.regId}/${this.formComponentService.recId}`]);
  //     } else {
  //       this.formComponentService.formId = activityData['ng5FormId'];
  //       this.formComponentService.getRecordData()
  //       .then(() => {
  //         this.formComponentService.afterGetFormOperation(activityData['ng5FormDoc'])
  //         .catch(err => {
  //           console.info(err);
  //         });
  //         // this.formComponentService.onRecordRefresh.next(true);
  //         this.formComponentService.formId = activityData['ng5FormId'];
  //         this.formComponentService.loading = false;
  //       }).catch(err => {
  //         this.errorOccured(err);
  //         console.info(err);
  //       });
  //     }
  //   } else {
  //     // this.location.back();
  //     // if (this.ngForm && this.ngForm.saveWithoutMove) {
  //     //   console.log('do not move back');
  //     // } else {
  //       // }
  //     this.goBack();
  //     this.formComponentService.loading = false;
  //   }
  // }


  updateAddress(event) {
    if (this.formComponentService && this.formComponentService.formData) {
      this.formComponentService.formData['companyAdress'] = (event && event.companyAdress) || '';
      this.formComponentService.formData['companyCity'] = (event && event.companyCity) || '';
      this.formComponentService.formData['shortName'] = (event && event.shortName) || '';
      this.formComponentService.formData['longName'] = (event && event.longName) || '';
      this.formComponentService.formData['postalcode'] = (event && event.postalcode) || '';
      this.formComponentService.formData['companyName'] = (event && event.companyName) || '';
    }
  }

  
  updateParentOnSave(event) {
    // this.editNg5Service.formData
    event = event || {};
    // tslint:disable-next-line:forin
    for (const i in event) {
      this.formComponentService.formData[i] = event[i];
    }
  }

  openParentRec() {
    // [routerLink]="['/oneRecordView/record/'+editNg5Service.formData['parentId']]" 
    if (this.formComponentService && this.formComponentService.formData && this.formComponentService.formData['parentId']) {
      // tslint:disable-next-line:max-line-length
      if (this.formComponentService.changedFormData && this.formComponentService.changedFormData['popupOptions'] && this.formComponentService.changedFormData['popupOptions']['dialogRef']) {
        this.formComponentService.changedFormData['popupOptions']['dialogRef'].close(null);
      }
      this.router.navigate([`/oneRecordView/record/${this.formComponentService.formData['parentId']}`]);
    }
  }

  deleteRecordConfirm() {
    this.confirmDialogRef = this.dialog.open(FuseConfirmDialogComponent, {
      disableClose: false
  });

  this.confirmDialogRef.componentInstance.confirmMessage = 'Are you sure you want to delete?';

  this.confirmDialogRef.afterClosed().subscribe(result => {
      if ( result ) {
        this.deleteRecord();
      } else {
        this.snackBar.open('You cancelled Deleting Record', 'Okay', {
          duration: 2000,
        });
      }
      this.confirmDialogRef = null;
  }, err => {
    console.info(err);
  });
  }


  deleteRecord() {
    if (this.formComponentService && this.formComponentService.recId && this.formComponentService.regId) {
      this.helperService.deleteRegisterRecordData(this.formComponentService.regId, this.formComponentService.recId)
      .then((data) => {
        this.snackBar.open('Record Deleted', 'Done', {
          duration: 2000,
        });
        this.formComponentService.closePopup(true);
      }).catch(err => {
        console.info(err);
        this.snackBar.open(err || 'Error occured while getting data', 'Done', {
          duration: 2000,
        });
      });
    }
  }

  onFieldValueUpdate(eve) {
    console.log("eve data = ", eve);
    this.formComponentService.checkForTabConditions();

    // this.form
    if (eve && eve.field && eve.field.haveRules) {
      // tslint:disable-next-line:max-line-length
      this.formComponentService.form = this.formRuleService.onValueUpdate(this.formComponentService.form, this.formComponentService.ngForm.rules, this.formComponentService.formData, eve.ruleIndex);
      console.log("this.formComponentService.form = ", this.formComponentService.form);
    }
  }
  updateDataFromCt(event) {
    if (event) {
      this.formComponentService.formData = Object.assign(this.formComponentService.formData, event);
      if (event.saveData) {
        this.formComponentService.saveFormData(true);
      }
    }
  }
  
  updateFormDataObj(event = {}) {
    this.formComponentService.formData = {
      ...this.formComponentService.formData,
      event
    }
  }

  changeSelection(event) {
    this.currentView = event.value;
    console.log("event", event);
    this.changeTab(event.value);
  }
}
