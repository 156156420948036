<div class="happ-list-field" *ngIf="buttons && buttons.length && !isErrorStatus">
    <!-- <p class="happ-field-title">{{ field.title | translate }} </p> -->
    <div class="happ-list" fxFlex="100" [fxLayout]="(field && field.showOnForm)? (field.flex || 'column') : 'row'" [ngClass]="{'formButton': (field && field.showOnForm), 'header-or-footer-button': (!field || (field && !field.showOnForm))}">
        <button [attr.aria-label]="(button.dest && button.dest.id)" [ngClass]="{'happ-small-button': (field && field.smallButton)}" class="happ-button-list happ-process-button" mat-raised-button (click)="onChange(button.dest)" role="listitem" *ngFor="let button of buttons">
            <p fxLayout="row" fxLayoutAlign="center center" class="m-0">
                {{(button.dest && button.dest.label) || ''}}
                <span *ngIf="field && field.showOnForm" fxFlex></span>
                <mat-icon style="font-weight: 700; margin: auto; margin-left: 4px; margin-right: 5px;">arrow_forward</mat-icon>
            </p>
        </button>
    </div>
</div>

<div *ngIf="isErrorStatus">
    <button [ngClass]="{'happ-small-button': (field && field.smallButton)}" class="happ-button-list happ-process-button" mat-raised-button (click)="retry()" role="listitem">
        <span>
            {{ 'ct.retry' | translate }}
        </span>
        <span fxFlex></span>
        <mat-icon style="font-weight: 700; margin: auto; margin-left: 4px; margin-right: 5px;">arrow_forward</mat-icon>
    </button>
    <button [ngClass]="{'happ-small-button': (field && field.smallButton)}" class="happ-button-list happ-process-button" mat-raised-button (click)="moveBack()" role="listitem">
        <span>
            {{ 'ct.move_back' | translate }}
        </span>
        <span fxFlex></span>
        <mat-icon style="font-weight: 700; margin: auto; margin-left: 4px; margin-right: 5px;">arrow_forward</mat-icon>
    </button>
    <button [ngClass]="{'happ-small-button': (field && field.smallButton)}" class="happ-button-list happ-process-button" mat-raised-button (click)="informAdmin()" role="listitem">
        <span>
            {{ 'ct.inform_admin' | translate }}
        </span>
        <span fxFlex></span>
        <mat-icon style="font-weight: 700; margin: auto; margin-left: 4px; margin-right: 5px;">arrow_forward</mat-icon>
    </button>
</div>