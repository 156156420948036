import { Component, OnInit, Input, Output, EventEmitter, OnChanges, SimpleChanges, ChangeDetectionStrategy, ChangeDetectorRef } from '@angular/core';
import { HelpersService } from 'app/main/content/services/helpers/helpers.service';
import { REGISTERED_DATE_TIME } from 'app/main/content/consts/global.const';
import * as _ from 'lodash';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-datatable-card-view',
  templateUrl: './datatable-card-view.component.html',
  styleUrls: ['./datatable-card-view.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class DatatableCardViewComponent implements OnInit, OnChanges {

  @Input() loadingIndicator: boolean;
  @Input() isAdmin: boolean;
  @Input() showPdfPreview: boolean;
  @Input() stepLabel: string;
  @Input() cardHeader: any;
  @Input() refresh: any;
  @Input() insertButtonText: string;
  @Input() showPdfPreviewWidth: string;
  @Input() pdfHeight: string;
  @Input() sortByField = REGISTERED_DATE_TIME;
  @Input() sortByOrder = 'desc';
  @Input() appHardFilter = '';
  @Input() mqFilterString: string;
  @Input() rows: any[];
  @Input() cardActions: any[];
  
  @Output() reportGenerationPopup: EventEmitter<any> = new EventEmitter();
  @Output() openWSAppsSettings: EventEmitter<any> = new EventEmitter();
  @Output() cardOptionChanged: EventEmitter<any> = new EventEmitter();
  @Output() editRecord: EventEmitter<any> = new EventEmitter();
  @Output() reloadDatatable: EventEmitter<any> = new EventEmitter();
  

  expandedRecordId: any;
  advanceFilter = false;
  inputFilter = {};
  mainRows = [];
  showMoreOptions = false;
  fastSearch = '';
  pdfUrl: any;
  loadingPdf: boolean;
  fileType: any;

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private _helperService: HelpersService,
    private sanitizer: DomSanitizer,
  ) { }

  ngOnInit() {
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.changeDetectorRef.detectChanges();
    this.changeDetectorRef.markForCheck();
    this.sortCardAction();
  }

  toggleViewToMore(recId: string) {
    if (this.expandedRecordId === recId) {
      this.expandedRecordId = null;
    } else {
      this.expandedRecordId = recId;
      this.showPdf(recId)
    }
  }

  editRecordClicked(recId: string) {
    this.editRecord.emit(recId);
  }

  cardOptionChangedClicked(event: any, recId: string) {
    console.log("clicked data 123");
    this.cardOptionChanged.emit({optionData: event, recId});
    
  }

  reportPopup() {
    this.reportGenerationPopup.emit(this.mqFilterString);
  }

  // updateDataLocally(updatedData: any, recId: string) {
  //   try {
  //     const recIndex = _.findIndex(this.rows, { _id: recId } as any);
  //     if (recIndex >= 0) {
  //       this.rows[recIndex][]
  //     }
  //   } catch (error) {
  //     console.log(error);
  //   }
  // }

  openWSAppsSettingsClicked(ele?: any) {
    this.openWSAppsSettings.emit(0);
  }
  refreshTable() {
    this.reloadDatatable.emit(true);
  }

  sortCardAction() {
    this.cardActions = this.cardActions || [];
    this.cardActions = this._helperService.transformSort(this.cardActions, 'sort');
  }

  toggleAdvanceFilter() {
    this.advanceFilter = !this.advanceFilter;
    this.inputFilter = {};
  }

  filterChanged() {
    this.rows = [...this.rows];
  }

  // getUrl(base64: any) {
  //   const blob = this._helperService.b64toBlob(base64, 'application/pdf', null);
  //   const blobUrl = URL.createObjectURL(blob);
  //   return this.sanitizer.bypassSecurityTrustResourceUrl(blobUrl);
  // }

  async showPdf(recId: string) {
    try {
      const tempItem: any = _.find(this.rows, { _id: recId } as any)
      console.log("tempItem", tempItem);
      this.loadingPdf = true;
      if (tempItem && tempItem.recordMainDocument) {
        const recordMainDocument = tempItem.recordMainDocument;
        const tempUrl = await this._helperService.getDocumentUrl(recordMainDocument);
        this.pdfUrl = this.sanitizer.bypassSecurityTrustResourceUrl(tempUrl.url);
        this.loadingPdf = false;
        this.fileType = this._helperService.getFileTypeByMime(tempUrl.ContentType)
        this.changeDetectorRef.detectChanges();
        console.log("this.pdfUrl", this.pdfUrl);
      }
    } catch (err) {
      console.info(err);
      this.loadingPdf = false;
    }
  }

  async downloadPdf(recordMainDocument: string) {
    try {
      if (recordMainDocument) {
        const doc = await this._helperService.getDocument(recordMainDocument);
        this._helperService.downloadDocument(doc);
      }
    } catch (err) {
      console.info(err);
    }
  }
}
