import { Injectable, Inject } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { environment } from 'environments/environment';
import { AuthService } from '../../pages/authentication/auth.service';
import { Locale } from '@fuse/services/translation-loader.service';
import { ComponentMapping } from '../../models/componentMapping.model';
import { MatDialogRef, MatDialog, MatSnackBar } from '@angular/material';
import { FuseConfirmDialogComponent } from '@fuse/components/confirm-dialog/confirm-dialog.component';
import { InputDialogComponent } from '../../global-component/input-dialog/input-dialog.component';
import { Observable } from 'rxjs';
import { IScanque } from '../../interfaces/scanque.interface';
import { TranslateService } from '@ngx-translate/core';
import { DescriptionPopupComponent } from '../../global-component/datatable/description-popup/description-popup.component';
import { ToastrService } from 'ngx-toastr';
import { IFormInterface } from '../../interfaces/form.interface';
import { IRegister } from '../../interfaces/register.interface';
import { Location } from '@angular/common';
import { ActivatedRouteSnapshot } from '@angular/router';
import { SUPER_ADMIN_EMAILS } from '../../consts/superAdminConfig.const';
import { VoipCallComponent } from '../../apps/voip-call/voip-call/voip-call.component';
import * as introJs from 'intro.js/intro.js';

const allMimeTypes = {
  "pdf": ["application/pdf"],
  "image": ["image/jpeg", "image/png", "image/gif", "image/svg+xml"],
  "audio": ["audio/mpeg", "audio/ogg", "audio/wav", "audio/x-wav", "audio/aac", "audio/webm", "audio/x-aiff", "audio/x-ms-wma", "audio/flac", "audio/x-flac", "audio/midi", "audio/x-midi", "audio/x-ape", "audio/x-m4a", "audio/3gpp", "audio/x-3gpp", "audio/x-caf", "audio/x-ms-asf", "audio/opus", "audio/x-snd"],
  "video": ["video/mp4", "video/ogg", "video/webm"],
  "text": ["text/plain", "text/html", "text/css", "text/javascript"],
  "application": ["application/json", "application/xml", "application/zip"]
};

@Injectable()
export class HelpersService {

  introJS = introJs();
  userToken: string;
  LOGIN_TIME_DIFF = 1000 * 60 * 60 * 8;
  REAUTH_TIME = 1000 * 60 * 60 * 6;
  SNACKBAR_DURATION = 2000;

  
  confirmDialogRef: MatDialogRef<FuseConfirmDialogComponent>;
  inputDialogRef: MatDialogRef<InputDialogComponent>;
  loading = false;
  translationData: any = {};
  loadingText = 'Loading...';
  globalLangMapping: any = {};

  constructor(
    private http: HttpClient,
    private authService: AuthService,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    private translate: TranslateService,
    private toastrService: ToastrService,
    private location: Location
    ) {
    this.getUserToken();
    setTimeout(() => {
      this.getAllLangObj();
    }, 500);
    this.translate.onLangChange.subscribe((event: any) => {
      this.getAllLangObj();
    });
  }

  getUserToken(): void {
    if (JSON.parse(localStorage.getItem('ngStorage-token'))) {
      this.userToken = JSON.parse(localStorage.getItem('ngStorage-token'))['token'];
    } else {
      console.log('No token');
    }
  }

  getAllLangObj() {
    this.translationData = this.translate.translations[this.translate.currentLang] || {};
    console.log("this.translate ", this.translate, this.translate.translations);
  }

  /*
   ********************************************************************************************************************************
   ********************************************************************************************************************************
                                               REGISTER HELPERS STARTS 
   ********************************************************************************************************************************
   ********************************************************************************************************************************
   */

  /**
  * get register list
  */
  getRegisterList(queryParams = ''): Promise<any> {
    return new Promise((resolve, reject) => {
      if (JSON.parse(localStorage.getItem('ngStorage-selectedOffice')) && JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id']) {
        this.notWorkspaceApis();
        // tslint:disable-next-line:max-line-length
        this.getRequest(`api/reg/register-list${queryParams}`)
          .then(resolve).catch(reject);
      } else {
        reject('No office Id found');
      }
    });
  }

  /**
   * 
   * @param regId 
   * get the register data from the regId
   */
  getRegisterData(regId: string): Promise<IRegister> {
    return new Promise((resolve, reject) => {
      if (regId) {
        this.getRequest(`api/reg/${regId}`)
          .then(resolve).catch(reject);
      } else {
        reject('No reg Id provided. Please check configuration or consult admin about it');
      }
    });
  }

  /**
   * 
   * @param regData 
   * create new Register
   */
  createRegisterData(regData: any): Promise<any> {
    return new Promise((resolve, reject) => {
      this.postRequest(`api/reg/new`, regData)
        .then(resolve).catch(reject);
    });
  }

  /**
   * 
   * @param regId 
   * @param regData 
   * update register
   */
  updateRegisterData(regId: string, regData: any): Promise<any> {
    return new Promise((resolve, reject) => {
      if (regId && regData) {
        delete regData._id;
        this.putRequest(`api/reg/${regId}`, regData)
          .then(resolve).catch(reject);
      } else {
        reject('No reg Id provided. Please check configuration or consult admin about it');
      }
    });
  }

  /**
   * 
   * @param regId 
   * delete register
   */
  deleteRegister(regId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      if (regId) {
        this.deleteRequest(`api/reg/${regId}`)
          .then(resolve).catch(reject);
      } else {
        reject('No reg Id provided. Please check configuration or consult admin about it');
      }
    });
  }

  /**----------------------------------------------------------------------------------------------------------------------------
    -----------------------------------------------------------------------------------------------------------------------------
                                              REGISTER HELPER ENDS
    -----------------------------------------------------------------------------------------------------------------------------
    -----------------------------------------------------------------------------------------------------------------------------
   */
  /*
   ********************************************************************************************************************************
   ********************************************************************************************************************************
                                               REGISTER RECORD HELPERS STARTS 
   ********************************************************************************************************************************
   ********************************************************************************************************************************
   */

  /**
  * 
  * @param regId 
  * @param recId 
  * get the record data from the register record
  */
  getRegisterRecordData(regId: string, recId: string, extraHeader: any = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      if (regId && recId) {
        this.getRequest(`api/reg/${regId}/${recId}`, extraHeader)
          .then(resolve).catch(reject);
      } else {
        reject('No reg Id or recId provided. Please check configuration or consult admin about it');
      }
    });
  }

  /**
   * 
   * @param regId 
   * @param recData 
   * create register record
   */
  createRegisterRecord(regId: string, recData: any, extraHeaders: any = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      if (regId) {
        delete recData['officeId'];
        delete recData['regId'];
        this.postRequest(`api/reg/${regId}/new`, recData, extraHeaders)
          .then(resolve).catch(reject);
      } else {
        reject('No regId provided. Please check configuration or consult admin about it');
      }
    });
  }

  /**
   * 
   * @param regId 
   * @param recId 
   * @param recData 
   * update register record
   */
  updateRegisterRecord(regId: string, recId: string, recData: any): Promise<any> {
    return new Promise((resolve, reject) => {
      if (regId && recId) {
        delete recData['officeId'];
        delete recData['regId'];
        this.putRequest(`api/reg/${regId}/${recId}`, recData)
          .then(resolve).catch(reject);
      } else {
        reject('No regId or recId provided. Please check configuration or consult admin about it');
      }
    });
  }

  deleteRegisterRecord(regId: string, recId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      if (regId && recId) {
        this.deleteRequest(`api/reg/${regId}/${recId}`)
          .then(resolve).catch(reject);
      } else {
        reject('No reg Id or recId provided. Please check configuration or consult admin about it');
      }
    });
  }

  /**----------------------------------------------------------------------------------------------------------------------------
    -----------------------------------------------------------------------------------------------------------------------------
                                              REGISTER RECORD HELPER ENDS
    -----------------------------------------------------------------------------------------------------------------------------
    -----------------------------------------------------------------------------------------------------------------------------
   */

  /*
   ********************************************************************************************************************************
   ********************************************************************************************************************************
                                               PROCESS HELPERS STARTS 
   ********************************************************************************************************************************
   ********************************************************************************************************************************
   */

  /**
   * 
   * get process list
   */
  getProcessList(): Promise<any> {
    return new Promise((resolve, reject) => {
      if (JSON.parse(localStorage.getItem('ngStorage-selectedOffice')) && JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id']) {
        this.notWorkspaceApis();
        // tslint:disable-next-line:max-line-length
        this.getRequest(`api/process.config/list?officeId=${JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id']}&userId=%23%23%23ADMIN%23%23%23`)
          .then(resolve).catch(reject);
      } else {
        reject('No office Id found');
      }
    });
  }

  /**
   * 
   * @param processId 
   * get process data form the process id
   */
  getProcess(processId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      if (processId) {
        this.notWorkspaceApis();
        this.getRequest(`api/process.config/${processId}`)
          .then(resolve).catch(reject);
      } else {
        reject('No processId provided. Please check configuration or consult admin about it');
      }
    });
  }

  /**
   * 
   * @param regId 
   * @param recId 
   * get the process buttons.
   */
  getProcessButtons(regId: string, recId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      if (regId && recId) {
        this.getRequest(`api/reg-process/${regId}/${recId}/getButtons`)
          .then(resolve).catch(reject);
      } else {
        reject('No regId or recId provided. Please check configuration or consult admin about it');
      }
    });
  }

  /**
 * 
 * @param regId 
 * @param recId 
 * @param buttonId 
 * get the process activity data.
 */
  getProcessActivityData(regId: string, recId: string, buttonId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      if (regId && recId) {
        this.postRequest(`api/reg-process/${regId}/${recId}/${buttonId}`, {})
          .then(resolve).catch(reject);
      } else {
        reject('No regId or recId provided. Please check configuration or consult admin about it');
      }
    });
  }

  /**
 * 
 * @param regId 
 * @param recId 
 * @param buttonId 
 * get the process activity data.
 */
getProcessActivityDataV2(instanceId: string, buttonId: string, comment?: string): Promise<any> {
  return new Promise((resolve, reject) => {
    if (instanceId) {
      this.getRequest(`api/reg-process/${instanceId}/${buttonId}?comment=${comment}`)
        .then(resolve).catch(reject);
    } else {
      reject('No regId or recId provided. Please check configuration or consult admin about it');
    }
  });
}

getProcessButtonByInstance(instanceId: string): Promise<any> {
  return new Promise((resolve, reject) => {
    if (instanceId) {
      this.getRequest(`api/reg-process/${instanceId}/getButtons`)
        .then(resolve).catch(reject);
    } else {
      reject('No instanceId provided. Please check configuration or consult admin about it');
    }
  });
}

  /**
   * 
   * @param processData 
   * create new process
   */
  createProcess(processData: any): Promise<any> {
    this.notWorkspaceApis();
    return this.postRequest(`api/process.config/new`, processData);
  }

  /**
   * 
   * @param processId 
   * @param updatedData 
   * update Process
   */
  updateProcess(processId: string, updatedData: any): Promise<any> {
    return new Promise((resolve, reject) => {
      if (processId) {
        this.notWorkspaceApis();
        this.putRequest(`api/process.config/${processId}`, updatedData)
          .then(resolve).catch(reject);
      } else {
        reject('No processId provided. Please check configuration or consult admin about it');
      }
    });
  }

  /**
   * 
   * @param processId 
   * delete process
   */
  deleteProcess(processId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      if (processId) {
        this.notWorkspaceApis();
        this.deleteRequest(`api/process.config/${processId}`)
          .then(resolve).catch(reject);
      } else {
        reject('No processId provided. Please check configuration or consult admin about it');
      }
    });
  }

  /**----------------------------------------------------------------------------------------------------------------------------
    -----------------------------------------------------------------------------------------------------------------------------
                                              PROCESS HELPER ENDS
    -----------------------------------------------------------------------------------------------------------------------------
    -----------------------------------------------------------------------------------------------------------------------------
   */



  /*
   ********************************************************************************************************************************
   ********************************************************************************************************************************
                                               INSTANCE HELPERS STARTS 
   ********************************************************************************************************************************
   ********************************************************************************************************************************
   */


  /**
   * 
   * @param processId 
   * @param instanceId 
   * get process instance data..
   */
  getProcessInstance(processId: string, instanceId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      if (processId && instanceId) {
        if (JSON.parse(localStorage.getItem('ngStorage-selectedOffice')) && JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id']) {
          this.getRequest(`api/offices/${JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id']}/process/${processId}/instances/${instanceId}`)
            .then(resolve).catch(reject);
        } else {
          reject('No office Id found');
        }
      } else {
        reject('No ProcessId or instanceId provided. Please check configuration or consult admin about it');
      }
    });
  }

    /**
   * 
   * 
   * get process instance data..
   */
  getAllInstance(filter): Promise<any> {
    return new Promise((resolve, reject) => {
        if (JSON.parse(localStorage.getItem('ngStorage-selectedOffice')) && JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id']) {
          filter = filter || {};
          filter['officeId'] = JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id'];
          this.postRequest(`api/process/allInstances`, filter)
            .then(resolve).catch(reject);
        } else {
          reject('No office Id found');
        }
      
    });
  }
    /**
   * 
   * @param processId 
   * @param instanceId 
   * get process instance data..
   */
  getAllRegisterRecord(filter): Promise<any> {
    return new Promise((resolve, reject) => {
        if (JSON.parse(localStorage.getItem('ngStorage-selectedOffice')) && JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id']) {
          filter = filter || {};
          filter['officeId'] = JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id'];
          this.postRequest(`api/reg/allRegRecord`, filter)
            .then(resolve).catch(reject);
        } else {
          reject('No office Id found');
        }
      
    });
  }

  /**----------------------------------------------------------------------------------------------------------------------------
    -----------------------------------------------------------------------------------------------------------------------------
                                              INSTANCE HELPER ENDS
    -----------------------------------------------------------------------------------------------------------------------------
    -----------------------------------------------------------------------------------------------------------------------------
   */



  /*
   ********************************************************************************************************************************
   ********************************************************************************************************************************
                                               FILES AND DOCUMENT HELPERS STARTS 
   ********************************************************************************************************************************
   ********************************************************************************************************************************
   */



  /**
   * 
   * @param documentId 
   * get the document data..
   */
  getDocument(documentId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      if (documentId) {
        this.getRequest(`api/offices/files/${documentId}/getBase64File`)
          .then(resolve).catch(reject);
      } else {
        reject('No documentId provided. Please check configuration or consult admin about it');
      }
    });
  }

  getDocumentUrl(documentId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      if (documentId) {
        this.getRequest(`api/offices/files/${documentId}/getSignedUrl`)
          .then(resolve).catch(reject);
      } else {
        reject('No documentId provided. Please check configuration or consult admin about it');
      }
    });
  }

  /**
   * 
   * @param documentId 
   * get the document data..
   */
  deleteDocument(documentId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      if (JSON.parse(localStorage.getItem('ngStorage-selectedOffice')) && JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id']) {
        if (documentId) {
          this.deleteRequest(`api/offices/files/${documentId}`)
            .then(resolve).catch(reject);
        } else {
          reject('No documentId provided. Please check configuration or consult admin about it');
        }
      } else {
        reject('No office found');
      }
    });
  }

  /**
 * 
 * @param documentId 
 * get the document data..
 */
  getSearchedFiles(params: any): Promise<any> {
    return new Promise((resolve, reject) => {
      try {
        if (this.unAuthProcess()) {
          this.getUserToken();
          // if (!this.userToken) {
          // }
          const headers = this.getHttpHeaders();
          this.http.get(`${environment.serverUrl}api/offices/files/searchFile`, { headers, params })
            .subscribe((data: Response) => {
              this.buildBackendResponse(data, resolve, reject);
            }, err => this.buildHttpError(err, reject));
          } else {
            // logout
            console.log("666666");
            this.authService.logOut();
          }
      } catch (err) {
        reject(err);
      }
    });
  }

  getHttpHeaders(extraHeader: any = {}): HttpHeaders {
    const headerObj: any = {
      'Authorization': this.userToken,
      'f-version': environment.version,
      'lang': this.translate.defaultLang,
      'officeId': JSON.parse(localStorage.getItem('selectedOfficeId')) || '',
      'virtualOrgId': JSON.parse(localStorage.getItem('selectedVirtualOrgId')) || '',
      ...extraHeader
    };
    if (localStorage.getItem('selected-workspace')) {
      headerObj['workspace'] = localStorage.getItem('selected-workspace') || '';
    }
    if (localStorage.getItem('wsAppData')) {
      let wsAppData: any = localStorage.getItem('wsAppData') || '';
      if (wsAppData) {
        wsAppData = JSON.parse(wsAppData);
        headerObj.workspaceIndex = wsAppData.stepNumber + "";
      }
    }
    if (localStorage.getItem('shared-token')) {
      headerObj['sharedToken'] = localStorage.getItem('shared-token') || '';
    }
    return new HttpHeaders(headerObj);
  }

  /**----------------------------------------------------------------------------------------------------------------------------
    -----------------------------------------------------------------------------------------------------------------------------
                                              FILES AND DOCUMENT HELPER ENDS
    -----------------------------------------------------------------------------------------------------------------------------
    -----------------------------------------------------------------------------------------------------------------------------
   */

  /*
   ********************************************************************************************************************************
   ********************************************************************************************************************************
                                               SCANQUE HELPERS STARTS 
   ********************************************************************************************************************************
   ********************************************************************************************************************************
   */


  /**----------------------------------------------------------------------------------------------------------------------------
    -----------------------------------------------------------------------------------------------------------------------------
                                              SCANQUE HELPER ENDS
    -----------------------------------------------------------------------------------------------------------------------------
    -----------------------------------------------------------------------------------------------------------------------------
   */

  /*
   ********************************************************************************************************************************
   ********************************************************************************************************************************
                                               FORM HELPERS STARTS 
   ********************************************************************************************************************************
   ********************************************************************************************************************************
   */



  /**
   * 
   * get the template list..
   */
  getFormList(): Promise<any> {
    return new Promise((resolve, reject) => {
      if (JSON.parse(localStorage.getItem('ngStorage-selectedOffice')) && JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id']) {
        this.getRequest(`api/offices/${JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id']}/forms/list`)
          .then(resolve).catch(reject);
      } else {
        reject('No office Id found');
      }
    });
  }

  /**
   * 
   * @param formId 
   * GET the form from the form id
   */
  getForm(formId: string, key: any = ''): Promise<IFormInterface> {
    return new Promise((resolve, reject) => {
      if (formId) {
        if (JSON.parse(localStorage.getItem('ngStorage-selectedOffice')) && JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id']) {
          this.getRequest(`api/offices/${JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id']}/forms/${formId}${key}`)
            .then(resolve).catch(reject);
        } else {
          this.getRequest(`api/offices/${environment.GLOBAL_OFFICE_ID}/forms/${formId}${key}`)
            .then(resolve).catch(reject);
        }
      } else {
        reject('No form Id provided. Please check configuration or consult admin about it');
      }
    });
  }

  // create form
  createForm(formData: any): Promise<any> {
    return new Promise((resolve, reject) => {
      if (JSON.parse(localStorage.getItem('ngStorage-selectedOffice')) && JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id']) {
        this.postRequest(`api/offices/${JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id']}/forms/new`, formData)
          .then(resolve).catch(reject);
      } else {
        reject('No office Id found');
      }
    });
  }

  /**
* 
* @param formId 
* GET the form from the form id
*/
  updateForm(formId: string, formData: any): Promise<any> {
    return new Promise((resolve, reject) => {
      if (formId) {
        if (JSON.parse(localStorage.getItem('ngStorage-selectedOffice')) && JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id']) {
          this.putRequest(`api/offices/${JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id']}/forms/${formId}`, formData)
            .then(resolve).catch(reject);
        } else {
          reject('No office Id found');
        }
      } else {
        reject('No form Id provided. Please check configuration or consult admin about it');
      }
    });
  }

   /**
   * 
   * @param formId 
   * GET the form from the form id
   */
  deleteForm(formId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      if (formId) {
        if (JSON.parse(localStorage.getItem('ngStorage-selectedOffice')) && JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id']) {
          this.deleteRequest(`api/offices/${JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id']}/forms/${formId}`)
            .then(resolve).catch(reject);
        } else {
          reject('No office Id found');
        }
      } else {
        reject('No form Id provided. Please check configuration or consult admin about it');
      }
    });
  }

  /**----------------------------------------------------------------------------------------------------------------------------
    -----------------------------------------------------------------------------------------------------------------------------
                                              FORM HELPER ENDS
    -----------------------------------------------------------------------------------------------------------------------------
    -----------------------------------------------------------------------------------------------------------------------------
   */



  /*
   ********************************************************************************************************************************
   ********************************************************************************************************************************
                                               TEMPLATE HELPERS STARTS 
   ********************************************************************************************************************************
   ********************************************************************************************************************************
   */



  /**
   * 
   * get the template list..
   */
  getTemplateList(params?): Promise<any> {
    return new Promise((resolve, reject) => {
      if (JSON.parse(localStorage.getItem('ngStorage-selectedOffice')) && JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id']) {
        params = params || '';
        this.getRequest(`api/offices/${JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id']}/templates/list${params}`)
          .then(resolve).catch(reject);
      } else {
        reject('No office Id found');
      }
    });
  }


  /**
   * 
   * @param templateId 
   * get the template data..
   */
  getTemplate(templateId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      if (templateId) {
        if (JSON.parse(localStorage.getItem('ngStorage-selectedOffice')) && JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id']) {
          this.getRequest(`api/offices/${JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id']}/templates/${templateId}`)
            .then(resolve).catch(reject);
        } else {
          reject('No office Id found');
        }
      } else {
        reject('No templateId provided. Please check configuration or consult admin about it');
      }
    });
  }

  /**
   * 
   * @param templateId 
   * get the template data..
   */
  getWebTemplate(templateId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      if (templateId) {
        if (JSON.parse(localStorage.getItem('ngStorage-selectedOffice')) && JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id']) {
          this.getRequest(`api/offices/${JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id']}/web-templates/${templateId}`)
            .then(resolve).catch(reject);
        } else {
          reject('No office Id found');
        }
      } else {
        reject('No templateId provided. Please check configuration or consult admin about it');
      }
    });
  }


  /**
   * 
   * @param templateData
   * create the template data..
   */
  createTemplate(templateData: any): Promise<any> {
    return new Promise((resolve, reject) => {
      if (templateData) {
        if (JSON.parse(localStorage.getItem('ngStorage-selectedOffice')) && JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id']) {
          this.postRequest(`api/offices/${JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id']}/templates/new`, templateData)
            .then(resolve).catch(reject);
        } else {
          reject('No office Id found');
        }
      } else {
        reject('No templateId provided. Please check configuration or consult admin about it');
      }
    });
  }

  /**
      * 
      * @param templateData
      * update the template data..
      */
  updateTemplate(templateId, updatedTemplateData: any): Promise<any> {
    return new Promise((resolve, reject) => {
      if (updatedTemplateData) {
        if (JSON.parse(localStorage.getItem('ngStorage-selectedOffice')) && JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id']) {
          // tslint:disable-next-line:max-line-length
          this.putRequest(`api/offices/${JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id']}/templates/${templateId}`, updatedTemplateData)
            .then(resolve).catch(reject);
        } else {
          reject('No office Id found');
        }
      } else {
        reject('No templateId provided. Please check configuration or consult admin about it');
      }
    });
  }


  /**
   * 
   * @param templateData
   * create the template data..
   */
  createWebTemplate(templateData: any): Promise<any> {
    return new Promise((resolve, reject) => {
      if (templateData) {
        if (JSON.parse(localStorage.getItem('ngStorage-selectedOffice')) && JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id']) {
          this.postRequest(`api/offices/${JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id']}/web-templates/new`, templateData)
            .then(resolve).catch(reject);
        } else {
          reject('No office Id found');
        }
      } else {
        reject('No templateId provided. Please check configuration or consult admin about it');
      }
    });
  }

  /**
      * 
      * @param templateData
      * update the template data..
      */
  updateWebTemplate(templateId, updatedTemplateData: any): Promise<any> {
    return new Promise((resolve, reject) => {
      if (updatedTemplateData) {
        if (JSON.parse(localStorage.getItem('ngStorage-selectedOffice')) && JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id']) {
          // tslint:disable-next-line:max-line-length
          this.putRequest(`api/offices/${JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id']}/web-templates/${templateId}`, updatedTemplateData)
            .then(resolve).catch(reject);
        } else {
          reject('No office Id found');
        }
      } else {
        reject('No templateId provided. Please check configuration or consult admin about it');
      }
    });
  }

     /**
   * 
   * @param formId 
   * GET the form from the form id
   */
  deleteTemplate(templateId: string): Promise<any> {
    return new Promise((resolve, reject) => {
      if (templateId) {
        if (JSON.parse(localStorage.getItem('ngStorage-selectedOffice')) && JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id']) {
          this.deleteRequest(`api/offices/${JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id']}/templates/${templateId}`)
            .then(resolve).catch(reject);
        } else {
          reject('No office Id found');
        }
      } else {
        reject('No template Id provided. Please check configuration or consult admin about it');
      }
    });
  }


  /**----------------------------------------------------------------------------------------------------------------------------
    -----------------------------------------------------------------------------------------------------------------------------
                                              TEMPLATE HELPER ENDS
    -----------------------------------------------------------------------------------------------------------------------------
    -----------------------------------------------------------------------------------------------------------------------------
   */


  /*
   ********************************************************************************************************************************
   ********************************************************************************************************************************
                                               LINKS HELPERS STARTS 
   ********************************************************************************************************************************
   ********************************************************************************************************************************
   */

  
  createNewLink(data: any): Promise<any> {
    return this.postRequest(`api/links`, data);
  }

  updateLink(linkId: string, data: any): Promise<any> {
    return this.putRequest(`api/links/${linkId}`, data);
  }
   
  deleteLink(linkId: string): Promise<any> {
    return this.deleteRequest(`api/links/${linkId}`);
  }
  
  /**----------------------------------------------------------------------------------------------------------------------------
    -----------------------------------------------------------------------------------------------------------------------------
                                              LINKS HELPER ENDS
    -----------------------------------------------------------------------------------------------------------------------------
    -----------------------------------------------------------------------------------------------------------------------------
   */

  /**
   * 
   * get the profile data..
   */
  getProfileData(): Promise<any> {
    return new Promise((resolve, reject) => {
      if (JSON.parse(localStorage.getItem('ngStorage-profile')) && JSON.parse(localStorage.getItem('ngStorage-profile'))['profileId']) {
        this.getRequest(`api/profile/${JSON.parse(localStorage.getItem('ngStorage-profile'))['profileId']}`)
          .then(resolve).catch(reject);
      } else {
        reject('No documentId provided. Please check configuration or consult admin about it');
      }
    });
  }

  // // update profile data 
  // updateProfileData(updatedData) {
  //   return new Promise((resolve, reject) => {
  //     if (JSON.parse(localStorage.getItem('ngStorage-profile')) && JSON.parse(localStorage.getItem('ngStorage-profile'))['profileId']) {
  //       this.http.patch(`${environment.serverUrl}api/profile/${JSON.parse(localStorage.getItem('ngStorage-profile'))['profileId']}`, updatedData)
  //       .subscribe((data: Response) => {
  //         this.buildBackendResponse(data, resolve, reject);
  //       }, err => this.buildHttpError(err, reject));
  //     } else {
  //       reject('No Profile id found');
  //     }
  //   });
  // }


  /**
   * updatedDoc: any
   * to update the office doc
   */
  updateOfficeDoc(updatedDoc: any): Promise<any> {
    return new Promise((resolve, reject) => {
      if (JSON.parse(localStorage.getItem('ngStorage-selectedOffice')) && JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id']) {
        this.patchRequest(`api/offices/${JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id']}`, updatedDoc)
          .then(resolve).catch(reject);
      } else {
        reject('No office Id found');
      }
    });
  }

  // update the office doc in localstorage
  updateLocalStorageOfficeDoc(updatedDoc): void {
    if (JSON.parse(localStorage.getItem('ngStorage-selectedOffice')) && JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id']) {
      updatedDoc['_id'] = JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id'];
      localStorage.setItem('ngStorage-selectedOffice', JSON.stringify(updatedDoc));
    }
  }

  
  pdfReport(getUrl: string, updatedData): Promise<any> {
    return new Promise((resolve, reject) => {
      try {
        if (this.unAuthProcess()) {
          this.getUserToken();
          // if (!this.userToken) {
          // }
          let headers = this.getHttpHeaders();
          headers = headers.set('Accept', 'application/pdf');
          if (getUrl && updatedData) {
            this.http.post(`${environment.serverUrl}${getUrl}`, updatedData, { headers: headers, responseType: 'blob' as 'json' })
              .subscribe((data) => {
                this.buildBackendResponse(data, resolve, reject);
              }, err => this.buildHttpError(err, reject));
          } else {
            reject('Error occurred while updating the document');
          }
        } else {
          console.log("666666");

          // logout
          this.authService.logOut();
        }
      } catch (err) {
        reject(err);
      }
    });
  }


  //  ************************ OTHER HELPERS ********************************* //


  
  createScanque(scanqueData): Promise<any> {
    return new Promise((resolve, reject) => {
      try {
        if (this.unAuthProcess()) {
          this.getUserToken();
          // if (!this.userToken) {
          // }
          const headers = this.getHttpHeaders();
          if (scanqueData) {
            this.http.post(`${environment.serverUrl}api/scanqueue/new`, scanqueData, { headers })
              .subscribe((data) => {
                resolve(data);
              }, err => this.buildHttpError(err, reject));
          } else {
            reject('Error occurred while updating the document');
          }
        } else {
          console.log("666666");

          // logout
          this.authService.logOut();
        }
      } catch (err) {
        reject(err);
      }
    });
  }

  dataTableRequest(regId: any, params: any): Promise<any> {
    return new Promise((resolve, reject) => {
      try {
        if (this.unAuthProcess()) {
          this.getUserToken();
          const headers = this.getHttpHeaders();
          if (regId && params) {
            this.http.post(`${environment.serverUrl}api/reg/${regId}/data-table`, params, { headers })
              .subscribe((data) => {
                if (data['success']) {
                  resolve(data);
                } else {
                  reject('Error Occurred while getting the data');
                }
              }, err => this.buildHttpError(err, reject));
          } else {
            reject('Error occurred while updating the document');
          }
        } else {
          console.log("666666");

          // logout
          this.authService.logOut();
        }
      } catch (err) {
        reject(err);
      }
    });
  }

  // type = 'form' or 'template'
  getFormOrTemplate(type: any, params: any): Promise<any> {
    return new Promise((resolve, reject) => {
      try {
        if (this.unAuthProcess()) {
          this.getUserToken();
          // if (!this.userToken) {
          // }
          const headers = this.getHttpHeaders();
          if (params) {
            const officeId = JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id'];
            this.http.post(`${environment.serverUrl}api/offices/${officeId}/${type}-dataTable`, params, { headers })
              .subscribe((data) => {
                if (data['success']) {
                  resolve((data && data['result']) || {});
                } else {
                  reject('Error Occurred while getting the data');
                }
              }, err => this.buildHttpError(err, reject));
          } else {
            reject('Error occurred while updating the document');
          }
        } else {
          console.log("666666");

          // logout
          this.authService.logOut();
        }
      } catch (err) {
        reject(err);
      }
    });
  }


  getRequest(getUrl: string, extraHeader = {}, skipAuth = false): Promise<any> {
    return new Promise((resolve, reject) => {
      try {
        if (this.unAuthProcess() || skipAuth) {
          this.getUserToken();
          // if (!this.userToken) {
          // }
          let headers;

          if (!skipAuth) {
            headers = this.getHttpHeaders(extraHeader);
          } else {
            headers = new HttpHeaders({})
          }
          this.http.get(`${environment.serverUrl}${getUrl}`, { headers })
            .subscribe((data: Response) => {
              this.buildBackendResponse(data, resolve, reject);
            }, err => this.buildHttpError(err, reject));
          } else {
            console.log("666666", getUrl);

            // logout
            this.authService.logOut();
          }
      } catch (err) {
        reject(err);
      }
    });
  }

  getRequestWithParams(getUrl: string, paramsObj: any): Promise<any> {
    return new Promise((resolve, reject) => {
      try {
        if (this.unAuthProcess()) {
          this.getUserToken();
          // if (!this.userToken) {
          // }
          const headers = this.getHttpHeaders();
          const ops = {
            headers,
            params: paramsObj
          };
          this.http.get(`${environment.serverUrl}${getUrl}`, ops)
            .subscribe((data: Response) => {
              this.buildBackendResponse(data, resolve, reject);
            }, err => this.buildHttpError(err, reject));
          } else {
            console.log("666666");

            // logout
            this.authService.logOut();
          }
      } catch (err) {
        reject(err);
      }
    });
  }


  patchRequest(getUrl: string, updatedData): Promise<any> {
    return new Promise((resolve, reject) => {
      try {
        if (this.unAuthProcess()) {
          this.getUserToken();
          // if (!this.userToken) {
          // }
          const headers = this.getHttpHeaders();
          if (getUrl && updatedData) {
            this.http.patch(`${environment.serverUrl}${getUrl}`, updatedData, { headers })
              .subscribe((data) => {
                this.buildBackendResponse(data, resolve, reject);
              }, err => this.buildHttpError(err, reject));
          } else {
            reject('Error occurred while updating the document');
          }
        } else {
          console.log("666666");

          // logout
          this.authService.logOut();
        }
      } catch (err) {
        reject(err);
      }
    });
  }

  putRequest(getUrl: string, updatedData): Promise<any> {
    return new Promise((resolve, reject) => {
      try {
        if (this.unAuthProcess()) {
          this.getUserToken();
          //   if (!this.userToken) {
          // }
          const headers = this.getHttpHeaders();
          if (getUrl && updatedData) {
            this.http.put(`${environment.serverUrl}${getUrl}`, updatedData, { headers })
              .subscribe((data) => {
                this.buildBackendResponse(data, resolve, reject);
              }, err => this.buildHttpError(err, reject));
          } else {
            reject('Error occurred while updating the document');
          }
        } else {
          console.log("666666");

          // logout
          this.authService.logOut();
        }
      } catch (err) {
        reject(err);
      }
    });
  }


  postRequest(getUrl: string, updatedData, extraHeaders: any = {}): Promise<any> {
    return new Promise((resolve, reject) => {
      try {
        if (this.unAuthProcess()) {
          this.getUserToken();
          // if (!this.userToken) {
          // }
          const headers = this.getHttpHeaders(extraHeaders);
          if (getUrl && updatedData) {
            this.http.post(`${environment.serverUrl}${getUrl}`, updatedData, { headers })
              .subscribe((data) => {
                this.buildBackendResponse(data, resolve, reject);
              }, err => this.buildHttpError(err, reject));
          } else {
            reject('Error occurred while updating the document');
          }
        } else {
          console.log("666666");

          // logout
          this.authService.logOut();
        }
      } catch (err) {
        reject(err);
      }
    });
  }

  deleteRequest(deleteUrl: string): Promise<any> {
    return new Promise((resolve, reject) => {
      try {
        if (this.unAuthProcess()) {
          this.getUserToken();
          // if (!this.userToken) {
          // }
          const headers = this.getHttpHeaders();
          this.http.delete(`${environment.serverUrl}${deleteUrl}`, { headers })
            .subscribe((data: Response) => {
              if (data && data['success']) {
                resolve(data['success']);
              } else {
                reject((data && data['error'] && data['error']['txt']) || 'Some error occured');
              }
              // this.buildBackendResponse(data, resolve, reject);
            }, err => this.buildHttpError(err, reject));
        } else {
          console.log("666666");

          // logout
          this.authService.logOut();
        }
      } catch (err) {
        reject(err);
      }
    });
  }

    // update profile data 
    updateProfileData(updatedData): Promise<any> {
      return new Promise((resolve, reject) => {
        if (JSON.parse(localStorage.getItem('ngStorage-profile')) && JSON.parse(localStorage.getItem('ngStorage-profile'))['profileId']) {
          this.patchRequest(`api/profile/${JSON.parse(localStorage.getItem('ngStorage-profile'))['profileId']}`, updatedData)
          .then(resolve).catch(reject);
        } else {
          reject('No Profile id found');
        }
      });
    }
    
  unAuthProcess(): boolean {
    const loginTimeDiff = this.getLoginTimeDiff();
    if (loginTimeDiff !== null) {
      if (loginTimeDiff < this.LOGIN_TIME_DIFF && loginTimeDiff > this.REAUTH_TIME) {
        // reauth and refresh token..
        this.refreshToken();
        return true;
      } else if (loginTimeDiff > this.LOGIN_TIME_DIFF) {
        return false;
      } else {
        return true;
      }
    } else {
      return false;
    }
  }

      /**
   * 
   * get register list
   */
  getSmallRegisterList(): Promise<any> {
    return new Promise((resolve, reject) => {
      if (JSON.parse(localStorage.getItem('ngStorage-selectedOffice')) && JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id']) {
        this.getRequest(`api/reg/register-list`)
        .then(resolve)
        .catch(reject);
      } else {
        reject('No office Id found');
      }
    });
  }

  refreshToken(): Promise<any> {
    return new Promise((resolve, reject) => {
      resolve('Depreciated method');
    });
  }

  getLoginTimeDiff(): number {
    if (JSON.parse(localStorage.getItem('ngStorage-token')) && JSON.parse(localStorage.getItem('ngStorage-token'))['date']) {
      // tslint:disable-next-line:radix
      const loginTime = parseInt(JSON.parse(localStorage.getItem('ngStorage-token'))['date']);
      return new Date().getTime() - loginTime;
    } else {
      return null;
    }
  }

  /**
   * 
   * @param data 
   * @param resolve 
   * @param reject 
   * build the success or error message from the response
   */
  buildBackendResponse(data, resolve, reject): void {
    if (data && data['success']) {
      if (data['result']) {
        resolve(data['result']);
      } else {
        reject('Response is empty. Please check the configuration');
      }
    } else {
      reject((data && data['error'] && data['error']['txt']) || 'Some error occured');
    }
  }

  /**
   * 
   * @param err 
   * @param reject 
   * build the error message from the http error
   */
  buildHttpError(err, reject): void {
    // if (err && err['status'] && err['message']) {
    //   reject(err['status'] + '-' + err['message']);
    // } else {
    //   reject('Error Occured while fetching the data');
    // }
    reject((err && err.error && err.error.message) || err.message || 'Error Occured while fetching the data');
  }


  getTrans(comps): Promise<Locale[]> {
    return new Promise((resolve, reject) => {
      if (this.globalLangMapping[comps]) {
        return resolve(this.globalLangMapping[comps]);
      }
      this.http.post(`${environment.serverUrl}api/i18n/langs`, {componentName: (ComponentMapping[comps] || [])})
      .subscribe((data) => {
        if (data && data['success']) {
          if (data['result']) {
            data = data['result'] || {};
          const temp: Locale[] = [];
          // tslint:disable-next-line:forin
          for (const i in data) {
            temp.push(data[i]);
          }
          this.getAllLangObj();
            resolve(temp);
            this.globalLangMapping[comps] = temp;
          } else {
            reject('Response is empty. Please check the configuration');
          }
        } else {
          reject((data && data['error'] && data['error']['txt']) || 'Some error occured');
        }
      }, err => this.buildHttpError(err, reject));
    });
  }

  getTransV2(comps): Promise<Locale[]> {
    return new Promise((resolve, reject) => {
      if (this.globalLangMapping[comps]) {
        return resolve(this.globalLangMapping[comps]);
      }
      this.http.post(`${environment.serverUrl}api/i18n/langs`, {componentName: [comps]})
      .subscribe((data) => {
        if (data && data['success']) {
          if (data['result']) {
            data = data['result'] || {};
          const temp: Locale[] = [];
          // tslint:disable-next-line:forin
          for (const i in data) {
            temp.push(data[i]);
          }
          this.getAllLangObj();
          this.globalLangMapping[comps] = temp;
            resolve(temp);
          } else {
            reject('Response is empty. Please check the configuration');
          }
        } else {
          reject((data && data['error'] && data['error']['txt']) || 'Some error occured');
        }
      }, err => this.buildHttpError(err, reject));
    });
  }

  /**
   * 
   * @param docInfo 
   * 
   * download the document
   */
  downloadDocument(docInfo): void {
    try {
      const blob = this.b64toBlob(docInfo['base64'], docInfo['mimetype'], null);
      const a = document.createElement('a');
      if (a.download !== undefined) {
        const blobUrl = URL.createObjectURL(blob);
        a.setAttribute('href', blobUrl);
        a.setAttribute('download', docInfo['originalname']);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
      }
    } catch (err) {
      console.info(err);
    }
  }

  /**
   * helper function for document download
   */
  b64toBlob(b64Data, contentType, sliceSize): any {
    try {
      contentType = contentType || '';
      sliceSize = sliceSize || 512;
      b64Data = b64Data || '';
      const byteCharacters = atob(b64Data);
      const byteArrays = [];
      for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);
        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }
      const blob = new Blob(byteArrays, { type: contentType });
      return blob;
    } catch (err) {
      console.info(err);
    }
  }

  
  openConfirmPopUp(message: string, isAutoClose = false, closeTime = 2000): Observable<any> {
    this.confirmDialogRef = this.dialog.open(FuseConfirmDialogComponent, {
        disableClose: false,
        data: {
          isAutoClose,
          closeTime
        }
    });

    this.confirmDialogRef.componentInstance.confirmMessage = message;
    return this.confirmDialogRef.afterClosed();
  }


  validateEmail(email: string): boolean {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(String(email).toLowerCase());
  }

  openInputPopUp(placeholder: string, confirmMessage: string, inputVal: string, isRequired = false): Observable<any> {
    this.inputDialogRef = this.dialog.open(InputDialogComponent, {
      disableClose: false,
      width: '350px',
      height: '130px',
      panelClass: 'happ-form-builder-dialog',
    });

    this.inputDialogRef.componentInstance.isRequired = isRequired;
    this.inputDialogRef.componentInstance.inputPlaceholder = placeholder;
    this.inputDialogRef.componentInstance.confirmMessage = confirmMessage;
    this.inputDialogRef.componentInstance.inputValue = inputVal;

    return this.inputDialogRef.afterClosed();
  }

  openTextareaPopUp(placeholder: string, confirmMessage: string, inputVal: string, isRequired = false): Observable<any> {
    this.inputDialogRef = this.dialog.open(InputDialogComponent, {
      disableClose: false,
      width: '550px',
      height: '530px',
      panelClass: 'happ-form-builder-dialog',
    });

    this.inputDialogRef.componentInstance.inputType = "textarea";
    this.inputDialogRef.componentInstance.isRequired = isRequired;
    this.inputDialogRef.componentInstance.inputPlaceholder = placeholder;
    this.inputDialogRef.componentInstance.confirmMessage = confirmMessage;
    this.inputDialogRef.componentInstance.inputValue = inputVal;

    return this.inputDialogRef.afterClosed();
  }

  openSnackBar(message: string, actionMsg?: string): void {
    this.snackBar.open(message, actionMsg || 'Okay', {
      duration: 2000,
    });
  }

  openTranslatedSnackBar(transCompKey: string, message: string, actionMsgKey?: string): void {
    const msg = this.getTranslatedValue(transCompKey, message) || '';
    const actionMsg = this.getTranslatedValue(transCompKey, actionMsgKey) || 'Okay';
    this.snackBar.open(msg, actionMsg, {
      duration: this.SNACKBAR_DURATION,
    });
  }

  getTranslatedValue(transCompKey: string, transKey: string): string {
    const langObj = this.translate.instant(transCompKey) || {};
    return langObj[transKey] || '';
  }

  // api/profile/workspaces
  getWorkspaceList(): Promise<any> {
    return new Promise((resolve, reject) => {
      this.getRequest(`api/profile/workspaces`)
      .then(resolve).catch(reject);
    });
  }

    // create register record
    create_slug_RegisterRecord(regSlug: string, recData: any): Promise<any> {
      return new Promise((resolve, reject) => {
        const workspaceSlug = localStorage.getItem('selected-workspace');
        if (workspaceSlug) {
          if (regSlug) {
            this.postRequest(`api/offices/work-space/${workspaceSlug}/reg/${regSlug}/new`, recData)
            .then(resolve).catch(reject);
          } else {
            reject('No workspace present');
          }
        } else {
          reject('No regId provided. Please check configuration or consult admin about it');
        }
      });
    }

    
  // leave workspace....
  leaveWorkspace(wsSlug: string): Promise<any> {
    return new Promise((resolve, reject) => {
      if (JSON.parse(localStorage.getItem('ngStorage-profile')) && JSON.parse(localStorage.getItem('ngStorage-profile'))['profileId'] && wsSlug) {    
        this.deleteRequest(`offices/work-space/:slug/users/${JSON.parse(localStorage.getItem('ngStorage-profile'))['profileId']}`)
        .then(resolve).catch(reject);
      } else {
        reject('No documentId provided. Please check configuration or consult admin about it');
      }
    });
  }

  // update register data
  updateWorkspaceData(wsSlug: string, wsData: any): Promise<any> {
    return new Promise((resolve, reject) => {
      if (wsSlug) {
        delete wsData['_id'];
        this.putRequest(`api/offices/work-space/${wsSlug}`, wsData)
        .then(resolve).catch(reject);
      } else {
        reject('No reg Id provided. Please check configuration or consult admin about it');
      }
    });
  }

      /**
   * 
   * @param regId 
   * get the redister data from the regId
   */
  getRegisterDataUsingSlug(regSlug: string): Promise<any> {
    return new Promise((resolve, reject) => {
        if (regSlug) {
          this.getRequest(`api/offices/work-space/${regSlug}`)
          .then(resolve).catch(reject);
        } else {
          reject('No reg Id provided. Please check configuration or consult admin about it');
        }
    });
  }


  getDefaultLangs(): Promise<any> {
    return new Promise((resolve, reject) => {
    console.log("999999999");
      this.http.get(`${environment.serverUrl}api/i18n/langs`)
      .subscribe((data: Response) => {
        // this.buildBackendResponse(data, resolve, reject);
        if (data && data['success']) {
          if (data['result']) {
            let temp = data['result'] || [];
            temp = temp.filter(ele => ele.active);
            temp = temp.map((ele) => {
              ele['id'] = ele.code;
              ele['title'] = ele.name;
              ele['flag'] = ele.code;
              return ele;
            });
            resolve(temp);
          } else {
            reject('Response is empty. Please check the configuration');
          }
        } else {
          reject((data && data['error'] && data['error']['txt']) || 'Some error occured');
        }
      }, err => this.buildHttpError(err, reject));
    });
  }

    // delete register record
  /**
   * 
   * @param regId 
   * @param recId 
   * get the record data from the register record
   */
  deleteRegisterRecordData(regId: string, recId: string): Promise<any> {
    return new Promise((resolve, reject) => {
        if (regId && recId) {
          this.deleteRequest(`api/reg/${regId}/${recId}`)
          .then(resolve).catch(reject);
        } else {
          reject('No reg Id or recId provided. Please check configuration or consult admin about it');
        }
    });
  }

  
  /**
   * 
   * @param regId 
   * get the record data from the register record
   */
  getRegisterRecordDataV2(recId: string): Promise<any> {
    return new Promise((resolve, reject) => {
        if (recId) {
          this.getRequest(`api/registries/record/${recId}`)
          .then(resolve).catch(reject);
        } else {
          reject('No recId provided. Please check configuration or consult admin about it');
        }
    });
  }

    /**
   * 
   * get the main menu list..
   */
  getOfficeMainMenu(): Promise<any> {
    return new Promise((resolve, reject) => {
      if (JSON.parse(localStorage.getItem('ngStorage-selectedOffice')) && JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id']) {
        this.getRequest(`api/offices/${JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id']}/menu-settings`)
          .then(resolve).catch(reject);
      } else {
        reject('No office Id found');
      }
    });
  }

      // update register record
      saveAndNewRegisterRecord(regId: string, recId: string, recData: any): Promise<any> {
        return new Promise((resolve, reject) => {
          if (regId && recId) {
            this.putRequest(`api/reg/${regId}/${recId}/save-and-new`, recData)
            .then(resolve).catch(reject);
          } else {
            reject('No regId or recId provided. Please check configuration or consult admin about it');
          }
        });
      }

      
  /**
   * get files.
  */
  getFiles(filters: string): Promise<any> {
    return new Promise((resolve, reject) => {
      if (JSON.parse(localStorage.getItem('ngStorage-selectedOffice')) && JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id']) {      
        // tslint:disable-next-line:max-line-length
        this.getRequest(`api/offices/files/list?officeId=${JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id']}${filters}`)
        .then(resolve)
        .catch(reject);
      } else {
        reject('No office Id found');
      }
    });
  }
  

  /**
   * upload files
  */
  uploadFiles(filters: string, formData): Promise<any> {
    return new Promise((resolve, reject) => {
      if (JSON.parse(localStorage.getItem('ngStorage-selectedOffice')) && JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id']) {      

        const profile = (localStorage.getItem('ngStorage-profile')) ? JSON.parse(localStorage.getItem('ngStorage-profile')) : null;
        if (profile) {
          
          const officeId = JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id'];
          const creatorId = profile['profileId'];
          const creatorLogin = profile['email'];
          // tslint:disable-next-line:max-line-length
          this.postRequest(`api/offices/files/upload?creatorId=${creatorId}&creatorLogin=${creatorLogin}&officeId=${officeId}${filters}`, formData)
          .then(resolve)
          .catch(reject);
        } else {
          reject('No profile found');
        }
      } else {
        reject('No office Id found');
      }
    });
  }

  
  getScanqueList(): Promise<Array<IScanque>> {
    return new Promise((resolve, reject) => {
      if (JSON.parse(localStorage.getItem('ngStorage-selectedOffice')) && JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id']) {
        this.getRequest(`api/scanqueue/officeId/${JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))['_id']}/list`)
        .then(resolve)
        .catch(reject);
      } else {
        reject('No office id');
      }
    });
  }
  
  notWorkspaceApis() {
    // localStorage.removeItem('selected-workspace');
  }

  getFormFlatField(obj: Array<any>): Array<any> {
    const ng5AllFields = [];
    obj = obj || [];
    obj.forEach((tabValue) => {
      if (tabValue.tabContent && typeof tabValue.tabContent === 'object') {
        tabValue.tabContent.forEach((value) => {
          if (value.field) {
            ng5AllFields.push(value);
          }
          if (value.children && typeof value.children === 'object') {
            value.children.forEach((childValue) => {
              if (childValue.field) {
                ng5AllFields.push(childValue);
              }
              if (childValue && typeof childValue === 'object') {
                childValue.forEach((nestedChildValue) => {
                  if (nestedChildValue.field) {
                    ng5AllFields.push(nestedChildValue);
                  }
                });
              }
            });
          }
        });
      }
    });
    return ng5AllFields;
  }

  
  getBrowserInfo() {
    const nVer = navigator.appVersion;
    const nAgt = navigator.userAgent;
    let browserName  = navigator.appName;
    let fullVersion  = '' + parseFloat(navigator.appVersion);
    let majorVersion = parseInt(navigator.appVersion, 10);
    let nameOffset, verOffset, ix;

    // In Opera, the true version is after "Opera" or after "Version"

    if ((verOffset = nAgt.indexOf('Opera')) !== -1) {
    browserName = 'Opera';
    fullVersion = nAgt.substring(verOffset + 6);
    if ((verOffset = nAgt.indexOf('Version')) !== -1) {
      fullVersion = nAgt.substring(verOffset + 8);
    }
    }
    // In MSIE, the true version is after "MSIE" in userAgent

    else if ((verOffset = nAgt.indexOf('MSIE')) !== -1) {
    browserName = 'Microsoft Internet Explorer';
    fullVersion = nAgt.substring(verOffset + 5);
    }
    // In Chrome, the true version is after "Chrome"

    else if ((verOffset = nAgt.indexOf('Chrome')) !== -1) {
    browserName = 'Chrome';
    fullVersion = nAgt.substring(verOffset + 7);
    }
    // In Safari, the true version is after "Safari" or after "Version"

    else if ((verOffset = nAgt.indexOf('Safari')) !== -1) {
    browserName = 'Safari';
    fullVersion = nAgt.substring(verOffset + 7);
    if ((verOffset = nAgt.indexOf('Version')) !== -1) {
      fullVersion = nAgt.substring(verOffset + 8);
    }
    }
    // In Firefox, the true version is after "Firefox"

    else if ((verOffset = nAgt.indexOf('Firefox')) !== -1) {
    browserName = 'Firefox';
    fullVersion = nAgt.substring(verOffset + 8);
    }
    // In most other browsers, "name/version" is at the end of userAgent

    else if ( (nameOffset = nAgt.lastIndexOf(' ') + 1) <
              (verOffset = nAgt.lastIndexOf('/')) )
    {
    browserName = nAgt.substring(nameOffset, verOffset);
    fullVersion = nAgt.substring(verOffset + 1);
    if (browserName.toLowerCase() === browserName.toUpperCase()) {
      browserName = navigator.appName;
    }
    }

    // trim the fullVersion string at semicolon/space if present

    if ((ix = fullVersion.indexOf(';')) !== -1) {
      fullVersion = fullVersion.substring(0, ix);
    }
    if ((ix = fullVersion.indexOf(' ')) !== -1) {
      fullVersion = fullVersion.substring(0, ix);
    }

    majorVersion = parseInt('' + fullVersion, 10);
    if (isNaN(majorVersion)) {
    fullVersion  = '' + parseFloat(navigator.appVersion);
    majorVersion = parseInt(navigator.appVersion, 10);
    }

    return {
      browserName,
      fullVersion,
      majorVersion,
      appName: navigator.appName,
      userAgent: navigator.userAgent
    };
  }
  
  transformSort(array: any[], field: string): any[] {
    if (!array || array && array.length === 0) {
    return [];
    }
    array.sort((a: any, b: any) => {
      if (a && b && a[field] < b[field]) {
        return -1;
      } else if (a && b && a[field] > b[field]) {
        return 1;
      } else {
        return 0;
      }
    });
    return array;
  }

  transformSortDec(array: any[], field: string): any[] {
    if (!array || array && array.length === 0) {
    return [];
    }
    array.sort((a: any, b: any) => {
      if (a && b && a[field] < b[field]) {
        return 1;
      } else if (a && b && a[field] > b[field]) {
        return -1;
      } else {
        return 0;
      }
    });
    return array;
  }

  
  openInstructionPopup(value, type?) {
    type = type || 'data';
    const dialogRef = this.dialog.open(DescriptionPopupComponent, {
      width: '60%',
      height: '60%',
      restoreFocus: false,
      panelClass: 'happ-form-builder-dialog',
      data: { type: type, value: value || '' }
    });
  }

  isNotEmpty(toCheck) {
    if (toCheck !== null && toCheck !== undefined) {
      return true;
    } else {
      return false;
    }
  }

  rowCalculateOnSave(fields, data) {
    let element2;
    if (fields['fields']) {
      data[fields['fields'][0]['operand']] = data[fields['fields'][0]['operand']] || 0;
      if ((parseFloat(data[fields['fields'][0]['operand']]) !== NaN)) {
      element2 = parseFloat(data[fields['fields'][0]['operand']]);
      }
      // tslint:disable-next-line:forin
      for (let j = 0; j < fields['fields'].length; j++) {
        const k = (j + 1);
          switch (fields['fields'][j]['operator']) {
            case '+':
              if (data && this.isNotEmpty(data[fields['fields'][j + 1]['operand']]) && parseFloat(data[fields['fields'][j + 1]['operand']]) !== NaN) {
                element2 += parseFloat(data[fields['fields'][j + 1]['operand']]);
              }

            break;
            case '-':
            if (data && this.isNotEmpty(data[fields['fields'][j + 1]['operand']]) && parseFloat(data[fields['fields'][j + 1]['operand']]) !== NaN) {
              element2 -= parseFloat(data[fields['fields'][j + 1]['operand']]);
            }

            break;
            case '*':
            if (data && this.isNotEmpty(data[fields['fields'][j + 1]['operand']]) && parseFloat(data[fields['fields'][j + 1]['operand']]) !== NaN) {    
                element2 *= parseFloat(data[fields['fields'][j + 1]['operand']]);
              }
            break;
            case '/':
            if (data && data[fields['fields'][j + 1]['operand']] && parseFloat(data[fields['fields'][j + 1]['operand']]) !== NaN) {    
                if (data[fields['fields'][j + 1]['operand']] !== 0 && parseFloat(data[fields['fields'][j + 1]['operand']]) !== 0) {
                  element2 /= parseFloat(data[fields['fields'][j + 1]['operand']]);
                }
              }
            break;
            case '%':
            if (data && this.isNotEmpty(data[fields['fields'][j + 1]['operand']]) && parseFloat(data[fields['fields'][j + 1]['operand']]) !== NaN) {                    
              element2 = element2 * (parseFloat(data[fields['fields'][j + 1]['operand']]) / 100);
                // if (parseFloat(data[fields['fields'][j + 1]['operand']]) !== 0) {
                // } else {
                //   element2
                // }
              }
            break;
            default:
          }
      }
    } else {
      element2 = 0;
    }
    return parseFloat(parseFloat(element2).toFixed(2));
    // if (this.isFloat(element2)) {
    // } else {
    //   return element2;
    // }
  }

  isInt(n){
    return Number(n) === n && n % 1 === 0;
  }

  isFloat(n){
    return Number(n) === n && n % 1 !== 0;
  }
  
  getXTypeVariables(obj, control_type, all?): Array<any> {
    const ng5AllFields = [];
    obj = obj || [];
    obj.forEach((tabValue) => {
      if (tabValue.tabContent && typeof tabValue.tabContent === 'object') {
        tabValue.tabContent.forEach((value) => {
          if (value.data !== null && (value.control_type === control_type || all) ) {
            ng5AllFields.push(value);
          }
          if (value.children && typeof value.children === 'object') {
            value.children.forEach((childValue) => {
              if (childValue.data !== null  && (childValue.control_type === control_type || all)) {
                ng5AllFields.push(childValue);
              }
              if (childValue && typeof childValue === 'object') {
                childValue.forEach((nestedChildValue) => {
                  // tslint:disable-next-line:max-line-length
                  if (nestedChildValue.data !== null  && (nestedChildValue.control_type === control_type || all)) {
                    ng5AllFields.push(nestedChildValue);
                  }
                });
              }
  
            });
          }
        });
      }
    });
    return ng5AllFields;
  }

  async showInfo(message: string, title: any = '', type: string = 'info', options: any = {}) {
    if (message) {
      message = await this.translate.get(message).toPromise();
    }
    if (title) {
      title = await this.translate.get(title).toPromise();
    }
    this.toastrService[type](message, title, options);
  }

  async getFullRegisterRecordList(regId: string, extraHeader = {}, filters: any = {}) {
    try {
      let query = '';
      if (Object.keys(filters).length) {
        query = '?';
        Object.keys(filters).forEach((ele) => {
          query = `${query}${ele}=${filters[ele]}`;
        });
      }
      const tt = await this.getRequest(`api/reg/${regId}/list${query}`, extraHeader);
      return tt || [];
    } catch (err) {
      throw err;
    }
  }

  async getDataForOptionBasedField(regId: string, columns: any, mqFilter: string, sort: string) {
    try {
      const sortObj = {};
      sortObj[sort || '_id'] = -1;
      const tt = await this.postRequest(`api/reg/${regId}/list`, { columns, hardFilter: mqFilter || '', sort: sortObj, limit: 200 });
      return (tt) || [];
    } catch (err) {
      throw err;
    }
  }

  checkMqFilter(mqFilter) {
    return this.postRequest(`api/offices/forms/checkMqFilter`, { mqFilter });
  }

  handleHttpError(error: any = {}) {
    switch (error.status) {
      case 401:
        this.openSnackBar(`Not logged in or the login has expired, please log in again.`, ``);
        break;
      case 403:
      case 404:
      case 500:
        this.openSnackBar(`${(error && error.error && error.error.message) || error.message}`);
        break;
      default:
        if (error instanceof HttpErrorResponse) {
          this.openSnackBar('I don\'t know the error, most of it is caused by the backend not supporting CORS or invalid configuration.');
        }
        break;
  }
}

  async getFuseConfig() {
    try {
      let officeData: any = {};
      let profileData: any = {};

      if (JSON.parse(localStorage.getItem('ngStorage-selectedOffice'))) {
        officeData = JSON.parse(localStorage.getItem('ngStorage-selectedOffice'));
      } else {
        throw new Error('No Office Doc Found. Please Make Sure you have some Office selected');
      }
      if (JSON.parse(localStorage.getItem('ngStorage-profile'))) {
        profileData = JSON.parse(localStorage.getItem('ngStorage-profile'));
      } else {
        throw new Error('No profile Found. Please login first');
      }
      const officeSettings = officeData.aotFuseSetting || {};
      let profileSettings = {};
      officeData.users = officeData.users || [];
      officeData.users.forEach((user) => {
        if (user && profileData && profileData.profileId && profileData.profileId === user.id) {
          profileSettings = user.aotFuseSetting || {};
        }
      });
      return Object.assign(officeSettings, profileSettings);
    } catch (error) {
      throw error;
    }
  }

  goBack() {
    this.location.back();
  }

  getCurrentLocalWorkspace() {
    return (localStorage.getItem('wsAppData') && JSON.parse(localStorage.getItem('wsAppData'))) || {};
  }

  getAllRoutesParams(route: ActivatedRouteSnapshot): any {
    const tempParams = JSON.parse(JSON.stringify(route.params));

    Object.keys(route.parent.params).forEach((key) => {
      tempParams[key] = route.parent.params[key];
    });
    return tempParams;
  }
  
  checkSuperAdmin() {
    if (JSON.parse(localStorage.getItem('ngStorage-token')) &&
          JSON.parse(localStorage.getItem('ngStorage-token'))['username'] && 
          SUPER_ADMIN_EMAILS.includes(JSON.parse(localStorage.getItem('ngStorage-token'))['username'])
      ) {
        return true;
      } else {
        return false;
      }
      return false;
  }

  async getActiveLangs(): Promise<any> {
    try {

      let data = await this.getRequest(`api/i18n/langs`, {}, true);
      data = data || [];
      data = data.filter((ele: any) => (ele && ele.active));
      return data;
    } catch (err) {
      throw err;
    }
  }

  formatTheLanguageArray(data: any[] = []) {
    return data.map((ele) => {
        if (ele) {
            return {
                id: ele.code,
                title: ele.name,
                flag: ele.code
            };
        }
    });
  }

  makeSharedServiceRequest(reqType: 'get' | 'post' | 'put' | 'delete', sharedOfficeId: string, route: string, body?: any) {
    return new Promise((resolve, reject) => {
      try {
        let headers: HttpHeaders;
        if (localStorage.getItem('selected-workspace')) {
          headers = new HttpHeaders({
            'Authorization': this.userToken,
            'f-version': environment.version,
            'sharedOfficeId': sharedOfficeId,
            'officeId': JSON.parse(localStorage.getItem('selectedOfficeId')) || '',
            'workspace': localStorage.getItem('selected-workspace') || ''
          });
        } else {
          headers = new HttpHeaders({
            'Authorization': this.userToken,
            'f-version': environment.version,
            'sharedOfficeId': sharedOfficeId,
            'officeId': JSON.parse(localStorage.getItem('selectedOfficeId')) || ''
          });
        }

        if (reqType === 'get' || reqType === 'delete') {
          this.http[reqType](`${environment.serverUrl}${route}`, { headers })
              .subscribe((data: Response) => {
                if (data && data['success']) {
                  this.buildBackendResponse(data, resolve, reject);
                } else {
                  reject((data && data['error'] && data['error']['txt']) || 'Some error occured');
                }
                // this.buildBackendResponse(data, resolve, reject);
              }, err => this.buildHttpError(err, reject));
        } else {
          this.http[reqType](`${environment.serverUrl}${route}`, body, { headers })
          .subscribe((data: Response) => {
            if (data && data['success']) {
              this.buildBackendResponse(data, resolve, reject);
            } else {
              reject((data && data['error'] && data['error']['txt']) || 'Some error occured');
            }
            // this.buildBackendResponse(data, resolve, reject);
          }, err => this.buildHttpError(err, reject));
        }
      } catch (error) {
        throw error;
      }
    });
  }

      /**
     * Helper to replace the variable (##INVIATION_TOKEN##) using the register record.
     * 
     * @param  {string=""} htmlString
     * @param  {any} registerRecord
     * @returns string
     */
    public replaceTheVariables(htmlString: string = '', registerRecord: any): string {
      try {
          if (registerRecord && typeof(htmlString) === "string") {
              htmlString = htmlString.replace(/##([\s\S]*?)##/g, (match, contents, _offset, _inputString) => {
                  return registerRecord[contents] || '';
              });
              return htmlString;
          } else {
              return htmlString;
          }
      } catch (err) {
          throw err;
      }
  }

  //  ************************ OTHER HELPERS ENDS ********************************* //

  openCallPopup(data: any) {
    data.helperInst = this;
    const dialogRef = this.dialog.open(VoipCallComponent, {
      width: '100%',
      height: '100%',
      restoreFocus: false,
      panelClass: 'happ-form-builder-dialog',
      data
    });
    return dialogRef;
  } 


  async getAboutOffice(officeId: string, query: any = {}) {
    let queryStr = '?';
    if (Object.keys(query)) {
      Object.keys(query).forEach((ele) => {
        queryStr = `${queryStr}&${ele}=${query[ele]}`;
      })
    }
    const temp: any = await this.getRequest(`api/external-page/${officeId}.pages.pinbox24.com${queryStr}`);
    return temp;
  }




  makeRequest(method: "get" | "post" | "put" | "delete" | any){
    this.http[method].subscribe((data) => {

    })
  }

  setIntroJson(steps = []) {
    this.introJS.setOptions({
        steps: steps || [
        {
          element: '#step1',
          intro: 'Welcome to your new app!',
          position: 'bottom'
        },
        {
          element: '#step2',
          intro: "Ok, wasn't that fun?",
          position: 'right'
        },
        {
          element: '[aria-label="open_office_dropdown"]',
          intro: "let's keep going",
          position: 'top'
        },
        {
          element: '#step4',
          intro: 'More features, more fun.',
          position: 'right'
        }
    ],
    showProgress: true
    });
  }

  startIntro() {
    // this.introJS.start();
  }

  getFileTypeByMime(mimeType: string) {
    const fileType = allMimeTypes[mimeType];
    return fileType || null;
  }

}
